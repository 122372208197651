import React, { useContext, useEffect } from 'react';
import { AuthorizationContext } from '../../context/AuthorizationContext';
import { QUERY_STATUS, ROUTE } from '../../constants';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getStartPageStatus } from '../../utils/startPage.utils';
import { useMutation } from 'react-query';
import AccountDetails from 'containers/AccountDetails/Loadable';
import Programs from 'containers/Programs';
import Category from 'containers/Category/Loadable';
import ChangePassword from 'containers/ChangePassword/Loadable';
import ChangePasswordConfirm from 'containers/ChangePasswordConfirm/Loadable';
import CodeNotWork from 'containers/CodeNotWork/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import DeleteAccount from 'containers/DeleteAccount/Loadable';
import DeleteAccountConfirm from 'containers/DeleteAccountConfirm/Loadable';
import EmployeeRepository from '../../repository/Repository/resources/EmployeeRepository';
import Faq from 'containers/Faq/Loadable';
import FeedBack from 'containers/FeedBack/Loadable';
import ForgotPassword from 'containers/ForgotPassword/Loadable';
import GeneralMenu from 'containers/GeneralMenu/Loadable';
import Program from 'containers/Program/Loadable';
import Notifications from 'containers/Notifications/Loadable';
import NotificationsEdit from 'containers/NotificationsEdit/Loadable';
import OrganizationDetails from 'containers/OrganizationDetails/Loadable';
import PrivacyPolicy from 'containers/PrivacyPolicy/Loadable';
import Profile from 'containers/Profile/Loadable';
import ProgrammeAttitude from 'containers/ProgrammeAttitude/Loadable';
import Regulations from 'containers/Regulations/Loadable';
import ReportProblem from 'containers/ReportProblem/Loadable';
import ReportProblemSuccess from 'containers/ReportProblemSuccess/Loadable';
import SignIn from 'containers/SignIn/Loadable';
import SignUp from 'containers/SignUp/Loadable';
import SignUpGetToken from 'containers/SignUpGetToken/Loadable';
import StartPage from 'containers/StartPage/Loadable';
import Task from 'containers/Task/Loadable';
import UserDetails from 'containers/UserDetails/Loadable';
import Contact from 'containers/Contact/Loadable';
import UserDetailsEdit from 'containers/UserDetailsEdit/Loadable';
import ForgotPasswordSuccess from 'containers/ForgotPasswordSuccess';
import LogOutConfirm from 'containers/LogOutConfirm';
import { getIsLogin } from '../../utils/authorization.utils';
import Instructions from '../SignUp/components/SignUpDescription/components/Instructions';
import { useNativeToken } from 'hooks/useNativeToken/index';
import NativeTokenFunctionHolder from '../../hooks/useNativeToken/nativeTokenFunctionHolder';

export default function App() {
  const {
    authorizationToken,
    registrationToken,
    setRegistrationStep,
    setIsRegistered,
    isRegistered,
    startPage
  } = useContext(AuthorizationContext);

  const [
    nativeToken,
    setNativeToken,
    isNativeTokenChanged,
    isNativeTokenWasSent,
    setIsNativeTokenWasSent
  ] = useNativeToken();

  NativeTokenFunctionHolder.setFunction(setNativeToken);

  const {
    data: employee,
    mutate: getEmployee,
    status: employeeStatus
  } = useMutation(EmployeeRepository.getEmployee);

  const { mutate: saveToken } = useMutation((token) => EmployeeRepository.saveToken(token));

  useEffect(() => {
    if (authorizationToken) {
      getEmployee();
    }
  }, [authorizationToken]);

  useEffect(() => {
    if (!nativeToken || !isRegistered) {
      return;
    }
    if (isNativeTokenChanged || !isNativeTokenWasSent) {
      saveToken({ token: nativeToken });
      setIsNativeTokenWasSent(true);
    }
  }, [isNativeTokenChanged, isRegistered]);

  useEffect(() => {
    if (startPage) {
      getStartPageStatus();
    }
  }, [startPage]);

  useEffect(() => {
    if (employeeStatus !== QUERY_STATUS.SUCCESS) {
      return;
    }

    try {
      const {
        data: { success }
      } = employee;

      if (success && !getIsLogin()) {
        setRegistrationStep(3);
        return;
      }

      if (success) {
        setIsRegistered(true);
      }
    } catch (error) {
      // do nothing
    }
  }, [employeeStatus]);

  if (!authorizationToken && !registrationToken) {
    return (
      <Switch>
        <Route exact path="/">
          <Redirect to={!startPage ? ROUTE.START_PAGE : ROUTE.SIGN_IN} />
        </Route>
        <Route exact path={ROUTE.SIGN_IN} component={SignIn} />
        <Route exact path={ROUTE.SIGN_UP} component={SignUpGetToken} />
        <Route exact path={ROUTE.CODE_NOT_WORK} component={CodeNotWork} />
        <Route exact path={ROUTE.REGULATIONS} component={Regulations} />
        <Route exact path={ROUTE.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={ROUTE.FORGOT_PASSWORD_SUCCESS} component={ForgotPasswordSuccess} />
        <Route exact path={ROUTE.START_PAGE} component={StartPage} />
        <Route>
          <Redirect to={!startPage ? ROUTE.START_PAGE : ROUTE.SIGN_IN} />
        </Route>
      </Switch>
    );
  }

  if ((authorizationToken || registrationToken) && !isRegistered) {
    return (
      <Switch>
        <Route exact path="/">
          <Redirect to={ROUTE.SIGN_UP} />
        </Route>
        <Route exact path={ROUTE.SIGN_UP} component={SignUp} />
        <Route exact path={ROUTE.CODE_NOT_WORK} component={CodeNotWork} />
        <Route exact path={ROUTE.REGULATIONS} component={Regulations} />
        <Route>
          <Redirect to={ROUTE.SIGN_UP} />
        </Route>
      </Switch>
    );
  }

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to={ROUTE.DASHBOARD} />
        </Route>
        <Route exact path={ROUTE.INSTRUCTION} component={Instructions} />
        <Route exact path={ROUTE.ACCOUNT_DETAILS} component={AccountDetails} />
        <Route exact path={ROUTE.CATEGORIES} component={Category} />
        <Route path={`${ROUTE.CATEGORY}/:id`} component={Programs} />
        <Route exact path={ROUTE.CHANGE_PASSWORD_CONFIRM} component={ChangePasswordConfirm} />
        <Route exact path={ROUTE.CHANGE_PASSWORD} component={ChangePassword} />
        <Route exact path={ROUTE.DASHBOARD} component={Dashboard} />
        <Route exact path={ROUTE.DELETE_ACCOUNT_CONFIRM} component={DeleteAccountConfirm} />
        <Route exact path={ROUTE.DELETE_ACCOUNT} component={DeleteAccount} />
        <Route exact path={ROUTE.FAQ} component={Faq} />
        <Route exact path={ROUTE.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={`${ROUTE.PROGRAM}/:id`} component={Program} />
        <Route
          path={`${ROUTE.PROGRAM}/:id/${ROUTE.PROGRAMME_ATTITUDE}`}
          component={ProgrammeAttitude}
        />
        <Route path={`${ROUTE.PROGRAM}/:id${ROUTE.FEEDBACK}`} component={FeedBack} />
        <Route path={`${ROUTE.PROGRAM}/:id/step/:day`} component={Task} />
        <Route exact path={ROUTE.MENU} component={GeneralMenu} />
        <Route exact path={ROUTE.NOTIFICATIONS_EDIT} component={NotificationsEdit} />
        <Route exact path={ROUTE.NOTIFICATIONS} component={Notifications} />
        <Route exact path={ROUTE.ORGANIZATION_DETAILS} component={OrganizationDetails} />
        <Route exact path={ROUTE.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact path={ROUTE.PROFILE} component={Profile} />
        <Route exact path={ROUTE.REGULATIONS} component={Regulations} />
        <Route exact path={ROUTE.REPORT_PROBLEM} component={ReportProblem} />
        <Route exact path={ROUTE.REPORT_PROBLEM_SUCCESS} component={ReportProblemSuccess} />
        <Route exact path={ROUTE.TASK} component={Task} />
        <Route exact path={ROUTE.USER_DETAILS} component={UserDetails} />
        <Route exact path={ROUTE.USER_DETAILS_EDIT} component={UserDetailsEdit} />
        <Route exact path={ROUTE.LOG_OUT_CONFIRM} component={LogOutConfirm} />
        <Route exact path={ROUTE.CONTACT} component={Contact} />
        <Route>
          <Redirect to={ROUTE.DASHBOARD} />
        </Route>
      </Switch>
    </>
  );
}
