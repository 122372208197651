import { createGlobalStyle } from 'styled-components';
import { Color } from './theme/_color';
import { FontWeight } from './theme/_fonts';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

  html,
  body {
    padding: 0;
    margin: 0;
  }
  
  * {
    -webkit-appearance: none;
  }

  body {
    font-family: 'DM Sans', sans-serif;
    min-height: 100vh;
    background-color: ${Color.white};
  }

  #app {
    position: relative;
    display: flex;
    flex-flow: wrap column;
    width: 100%;
    min-height: 100vh;
  }
  
  #root {
    position: relative;
    display: block;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }

  input::placeholder, textarea::placeholder  {
    font-size: 16px;
    line-height: 17px;
    font-weight: ${FontWeight.Normal};
    color: ${Color.gray_4};
    opacity: 1;
  }

  input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-size: 16px;
    line-height: 17px;
    font-weight: ${FontWeight.Normal};
    color: ${Color.gray_4};
  }

  input::-ms-input-placeholder, textarea::-ms-input-placeholder {
    font-size: 16px;
    line-height: 17px;
    font-weight: ${FontWeight.Normal};
    color: ${Color.gray_4};
  }

  textarea:focus, input:focus{
    outline: none;
  }
`;

export default GlobalStyle;
