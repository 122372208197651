import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconInfo from '../../images/icon-info.svg';

const Info = ({ text }) => (
  <Styled.Wrapper>
    <Styled.Img src={iconInfo} />
    {text}
  </Styled.Wrapper>
);

Info.propTypes = {
  text: PropTypes.string.isRequired
};

export default Info;
