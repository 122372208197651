import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  min-height: 100vh;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: ${({ top }) => `${top}` || '0px'};
  padding-bottom: ${({ bottom }) => bottom && '80px'};
  animation: ${fade} 0.3s linear;
`;
