import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundPowerAudit = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.powerAudit1} top={33} left={125} width={127} height={95} index={1} />
    <Image src={IMAGES.powerAudit2} top={30} left={0} width={112} height={134} index={2} />
    <Image src={IMAGES.powerAudit3} top={0} right={0} width={191} height={143} index={4} />
    <Image src={IMAGES.powerAudit4} top={0} right={0} width={106} height={145} index={3} />
  </Styled.Wrapper>
);

export default BackgroundPowerAudit;
