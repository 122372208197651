import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const InputEmail = ({ error, name, onChange, placeholder, type, value }) => (
  <Styled.Wrapper>
    <Styled.Input
      name={name}
      onChange={onChange}
      type={type}
      value={value}
      placeholder={placeholder}
      error={error}
    />
    {error ? <Styled.Error>{error}</Styled.Error> : null}
  </Styled.Wrapper>
);

InputEmail.defaultProps = {
  name: '',
  placeholder: '',
  type: 'email'
};

InputEmail.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  value: PropTypes.string.isRequired
};

export default InputEmail;
