import React from 'react';
import PropTypes from 'prop-types';
import Back from '../Back';
import Hamburger from '../Hamburger';
import * as Styled from './styled';

import iconEdit from '../../images/icon-edit.svg';
import iconClose from '../../images/icon-close.svg';

const NavBar = ({
  handleClickBack,
  handleClickEdit,
  handleClickClose,
  isBackButton,
  isHamburger,
  title
}) => (
  <Styled.Wrapper
    isEdit={handleClickEdit ? 1 : 0}
    isClose={handleClickClose ? 1 : 0}
    isHamburger={isHamburger ? 1 : 0}>
    {isBackButton ? <Back mgrRight="24px" handleClick={handleClickBack} /> : null}

    {title ? <Styled.Title>{title}</Styled.Title> : null}

    {handleClickEdit ? (
      <Styled.Edit type="button" onClick={handleClickEdit}>
        <Styled.IconEdit src={iconEdit} />
      </Styled.Edit>
    ) : null}

    {handleClickClose ? (
      <Styled.Close type="button" onClick={handleClickClose}>
        <Styled.IconClose src={iconClose} />
      </Styled.Close>
    ) : null}

    {isHamburger ? <Hamburger /> : null}
  </Styled.Wrapper>
);

NavBar.defaultProps = {
  handleClickClose: null,
  handleClickEdit: null,
  isBackButton: false,
  isHamburger: false,
  title: ''
};

NavBar.propTypes = {
  handleClickBack: PropTypes.func,
  handleClickClose: PropTypes.func,
  handleClickEdit: PropTypes.func,
  isBackButton: PropTypes.bool,
  isHamburger: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default NavBar;
