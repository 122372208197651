import React from 'react';
import Layout from '../../containers/Layout/Layout';
import { Background, Button } from '../../components';
import * as Styled from './styled';

import IconCheckCircle from '../../images/icon-check-circle.svg';
import PropTypes from 'prop-types';

const Confirm = ({ text, handleClick, buttonLabel, backgroundType }) => (
  <Layout isBottom top="0">
    <Styled.BackgroundWrapper>
      <Background type={backgroundType} />
    </Styled.BackgroundWrapper>

    <Styled.Icon src={IconCheckCircle} />

    <Styled.Title>{text}</Styled.Title>

    <Styled.ButtonWrapper>
      <Button active handleClick={handleClick} label={buttonLabel} />
    </Styled.ButtonWrapper>
  </Layout>
);

Confirm.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  backgroundType: PropTypes.string,
  buttonLabel: PropTypes.string
};

export default Confirm;
