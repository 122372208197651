import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  width: auto;
`;

export const Image = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 6px;
`;

export const Name = styled.span`
  display: block;
  font-size: 16px;
  line-height: 17px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.gray_3};
`;
