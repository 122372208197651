import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundActiveProgram = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.activeProgram_1} top={0} left={66} width={62} height={115} index={4} />
    <Image src={IMAGES.activeProgram_2} top={15} left={8} width={84} height={78} index={2} />
    <Image src={IMAGES.activeProgram_3} top={5} right={0} width={82} height={63} index={3} />
    <Image src={IMAGES.activeProgram_4} top={101} left={75} width={31} height={26} index={1} />
  </Styled.Wrapper>
);

export default BackgroundActiveProgram;
