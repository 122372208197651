import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  overflow: hidden;
`;

export const Poster = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  height: 92px;
  border-radius: 13px;
  overflow: hidden;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 92px;
  object-fit: cover;
  object-position: bottom;
`;

export const Title = styled.h2`
  width: 100%;
  margin: 4px 0 4px;
  font-size: 16px;
  line-height: 21px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.neutralBlack};
`;

export const Details = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin-top: auto;
`;

export const Icon = styled.img`
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
`;

export const Days = styled.span`
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  margin-right: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray_4};
  font-weight: ${FontWeight.Normal};
`;

export const Category = styled.span`
  position: relative;
  display: block;
  padding-left: 13px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray_4};
  font-weight: ${FontWeight.Normal};

  &:first-letter {
    text-transform: uppercase;
  }

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${Color.gray_2};
  }
`;

export const Desc = styled.div`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.neutralBlack};
`;
