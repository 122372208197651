import React from 'react';
import PropTypes from 'prop-types';
import { MOOD_TYPE } from '../../constants';
import * as Styled from './styled';

import iconAverage from '../../images/mood/average.svg';
import iconBad from '../../images/mood/bad.svg';
import iconGood from '../../images/mood/good.svg';
import iconGreat from '../../images/mood/great.svg';
import iconSad from '../../images/mood/sad.svg';

const MoodButton = ({ color, type, label, onClick, dataValue }) => {
  const renderImage = (param) => {
    switch (param) {
      case MOOD_TYPE.AVERAGE: {
        return <Styled.Icon src={iconAverage} />;
      }
      case MOOD_TYPE.BAD: {
        return <Styled.Icon src={iconBad} />;
      }
      case MOOD_TYPE.GOOD: {
        return <Styled.Icon src={iconGood} />;
      }
      case MOOD_TYPE.GREAT: {
        return <Styled.Icon src={iconGreat} />;
      }
      case MOOD_TYPE.SAD: {
        return <Styled.Icon src={iconSad} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Styled.Wrapper onClick={onClick} data-value={dataValue}>
      <Styled.Image color={color}>{renderImage(type)}</Styled.Image>
      <Styled.Label>{label}</Styled.Label>
    </Styled.Wrapper>
  );
};

MoodButton.propTypes = {
  dataValue: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default MoodButton;
