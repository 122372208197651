import styled from 'styled-components';
import { Color } from '../../theme/_color';

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  flex-flow: wrap column;
`;

export const Input = styled.input`
  display: none;
  opacity: 0;
  position: absolute;
  top: -999px;
  left: -999px;
`;

export const Text = styled.span`
  display: block;
  width: 100%;
  max-width: 50px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  width: 50px;
  height: 50px;
  border-radius: 7px;
  border: ${({ active }) => (active ? `2px solid ${Color.black}` : 0)}
  background: ${({ color }) => (color ? color : Color.white)};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
`;

export const Icon = styled.img`
  display: block;
  width: 29px;
  height: 29px;
`;
