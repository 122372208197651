import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';
import { Paragraph } from '../../theme/_typography';

export const Slider = styled.div`
  display: flex;
  flex-flow: nowrap row;
  width: calc(100% + 48px);
  margin: 0 -24px;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    flex-shrink: 0;
    height: auto;
    width: 154px;
    margin-right: 14px;

    &:first-child {
      margin-left: 24px;
    }

    &:last-child {
      margin-right: 24px;
    }
  }
`;

export const Panel = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap column;
  align-items: flex-start;
  width: 100%;
  padding-top: ${({ top }) => top};
`;

export const Title = styled(Paragraph)`
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.black};
`;
