import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundDeleteAccount = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.deleteAccount_1} top={45} left={160} width={106} height={116} index={5} />
    <Image src={IMAGES.deleteAccount_2} top={94} left={165} width={129} height={150} index={1} />
    <Image src={IMAGES.deleteAccount_3} top={-6} right={0} width={113} height={218} index={2} />
    <Image src={IMAGES.deleteAccount_4} top={0} right={0} width={375} height={326} index={4} />
    <Image src={IMAGES.deleteAccount_5} top={-6} left={0} width={199} height={319} index={3} />
  </Styled.Wrapper>
);

export default BackgroundDeleteAccount;
