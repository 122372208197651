export { default as BackgroundAccountDetails } from './BackgroundAccountDetails';
export { default as BackgroundActiveProgram } from './BackgroundActiveProgram';
export { default as BackgroundChangePassword } from './BackgroundChangePassword';
export { default as BackgroundChangePasswordConfirm } from './BackgroundChangePasswordConfirm';
export { default as BackgroundCodeNotWork } from './BackgroundCodeNotWork';
export { default as BackgroundDeleteAccount } from './BackgroundDeleteAccount';
export { default as BackgroundDeleteAccountConfirm } from './BackgroundDeleteAccountConfirm';
export { default as BackgroundFeedBack1 } from './BackgroundFeedBack1';
export { default as BackgroundFeedBack2 } from './BackgroundFeedBack2';
export { default as BackgroundFeedBack3 } from './BackgroundFeedBack3';
export { default as BackgroundForgotPassword } from './BackgroundForgotPassword';
export { default as BackgroundForgotPasswordConfirm } from './BackgroundForgotPasswordConfirm';
export { default as BackgroundNotifications } from './BackgroundNotifications';
export { default as BackgroundNotificationsEdit } from './BackgroundNotificationsEdit';
export { default as BackgroundPowerAudit } from './BackgroundPowerAudit';
export { default as BackgroundProgrammeAttitude } from './BackgroundProgrammeAttitude';
export { default as BackgroundRegister } from './BackgroundRegister';
export { default as BackgroundReportProblemSuccess } from './BackgroundReportProblemSuccess';
export { default as BackgroundSingIn } from './BackgroundSingIn';
export { default as BackgroundSingUp } from './BackgroundSingUp';
export { default as BackgroundStartPage1 } from './BackgroundStartPage1';
export { default as BackgroundStartPage2 } from './BackgroundStartPage2';
export { default as BackgroundStartPage3 } from './BackgroundStartPage3';
export { default as BackgroundTaskSummaryTop } from './BackgroundTaskSummaryTop';
export { default as BackgroundTaskSummaryBottom } from './BackgroundTaskSummaryBottom';
export { default as BackgroundUserDetails } from './BackgroundUserDetails';
