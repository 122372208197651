import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import * as Styled from './styled';

import IconLogOut from '../../images/icon-logout.svg';

const LogOut = ({ handleClick }) => {
  return (
    <Styled.Wrapper onClick={handleClick}>
      <Styled.Image src={IconLogOut} />
      <Styled.Name>
        <FormattedMessage {...messages.name} />
      </Styled.Name>
    </Styled.Wrapper>
  );
};

LogOut.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default LogOut;
