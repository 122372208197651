import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundAccountDetails = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.accountDetails_1} top={0} right={0} width={171} height={213} index={1} />
    <Image src={IMAGES.accountDetails_2} top={136} left={15} width={175} height={169} index={2} />
    <Image src={IMAGES.accountDetails_3} top={46} right={67} width={172} height={259} index={3} />
    <Image src={IMAGES.accountDetails_4} top={209} right={0} width={375} height={96} index={4} />
  </Styled.Wrapper>
);

export default BackgroundAccountDetails;
