import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundNotifications = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.notifications} top={0} left={1} width={374} height={485} index={1} />
  </Styled.Wrapper>
);

export default BackgroundNotifications;
