import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import Arrow from '../../images/icon-arrow.svg';

const Collapse = ({ content, title }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);

  return (
    <Styled.Wrapper active={open}>
      <Styled.Title onClick={handleClick}>
        {title}
        <Styled.Icon rotate={open ? 1 : 0}>
          <img src={Arrow} alt="" />
        </Styled.Icon>
      </Styled.Title>

      <Styled.Description active={open}>{content}</Styled.Description>
    </Styled.Wrapper>
  );
};

Collapse.defaultProps = {
  content: '',
  title: ''
};

Collapse.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string
};

export default Collapse;
