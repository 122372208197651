import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as Styled from './styled';

const NavigationItem = ({ icon, iconActive, length, name, path }) => {
  const [active, setActive] = useState(false);
  const match = useRouteMatch();

  useEffect(() => {
    if (match && match.path === path) {
      setActive(true);
    }
  }, [match]);

  return (
    <Styled.Wrapper length={length} exact to={path} isActive={() => active}>
      <Styled.Icon src={active ? iconActive : icon} />
      <Styled.Name active={active}>{name}</Styled.Name>
    </Styled.Wrapper>
  );
};

NavigationItem.propTypes = {
  name: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  iconActive: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired
};

export default NavigationItem;
