import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const Input = ({ error, id, name, onChange, placeholder, type, value }) => (
  <Styled.Wrapper>
    <Styled.Input
      id={id}
      type={type}
      onChange={onChange}
      name={name}
      value={value}
      placeholder={placeholder}
      error={error}
    />

    {error ? <Styled.Error>{error}</Styled.Error> : null}
  </Styled.Wrapper>
);

Input.defaultProps = {
  error: null,
  placeholder: '',
  type: 'text'
};

Input.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  value: PropTypes.any
};

export default Input;
