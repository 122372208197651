import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled(NavLink)`
  position: relative;
  display: flex;
  flex-flow: wrap column;
  align-items: center;
  width: ${({ length }) => `calc(100% / ${length})`};
  padding-top: 17px;
  text-decoration: none;
`;

export const Icon = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-height: 24px;
`;

export const Name = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 10px;
  line-height: 13px;
  font-weight: ${({ active }) => (active ? FontWeight.Bold : FontWeight.Normal)};
  color: ${Color.neutralBlack};
  text-transform: uppercase;
`;
