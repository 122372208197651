import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconCheck from '../../images/icon-check-white.svg';

const Stepper = ({ currentStep: _currentStep, numberOfSteps }) => {
  const currentStep = parseInt(_currentStep);

  const renderSteps = Array.from({ length: numberOfSteps }, (_, index) => {
    const step = index + 1;

    const renderStep = (() => {
      if (step === currentStep) {
        return <Styled.StepActive>{step}</Styled.StepActive>;
      }

      if (step < currentStep) {
        return (
          <Styled.StepDone>
            <Styled.Icon src={iconCheck} />
          </Styled.StepDone>
        );
      }

      return <Styled.Step>{step}</Styled.Step>;
    })();

    return (
      <Styled.Stepper
        key={index}
        line={step < numberOfSteps ? 1 : 0}
        colorLine={step < currentStep ? 1 : 0}>
        {renderStep}
      </Styled.Stepper>
    );
  });

  const step = currentStep - 1;

  return (
    <Styled.Wrapper>
      <Styled.StepperWrapper position={numberOfSteps > 3} step={step >= 3 ? step : 3}>
        {renderSteps}
      </Styled.StepperWrapper>
    </Styled.Wrapper>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  numberOfSteps: PropTypes.number.isRequired
};

export default Stepper;
