import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Image = styled.img`
  display: block;
  width: 43px;
  height: 41px;
`;
