import styled from 'styled-components';
import { Color } from '../../../../../../theme/_color';
import { FontWeight } from '../../../../../../theme/_fonts';

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  padding: 24px 24px 0;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  min-height: 48px;
  padding-left: 67px;
  font-size: 16px;
  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &:before {
    content: '${({ number }) => number}';
    position: absolute;
    top: 0;
    left: 3px;
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid ${Color.primaryBrand};
    font-size: 16px;
    line-height: 17px;
    font-weight: ${FontWeight.Bold};
  }
`;
