import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundNotificationsEdit = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.notificationsEdit} top={-83} right={0} width={375} height={539} index={1} />
  </Styled.Wrapper>
);

export default BackgroundNotificationsEdit;
