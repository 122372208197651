import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconPassword from '../../images/icon-password.svg';
import iconPasswordActive from '../../images/icon-password-active.svg';

const InputPassword = ({ error, value, placeholder, name, onChange, onBlur }) => {
  const [showPass, setShowPass] = useState(false);
  const handleClick = () => setShowPass((prev) => !prev);

  return (
    <Styled.Wrapper>
      <Styled.InputWrapper error={error}>
        <Styled.Input
          name={name}
          type={showPass ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          placeholder={placeholder}
        />
        <Styled.ShowPassword active={showPass} onClick={handleClick}>
          <Styled.Image src={showPass ? iconPasswordActive : iconPassword} />
        </Styled.ShowPassword>
      </Styled.InputWrapper>

      {error ? <Styled.Error>{error}</Styled.Error> : null}
    </Styled.Wrapper>
  );
};

InputPassword.defaultProps = {
  active: false,
  placeholder: ''
};

InputPassword.propTypes = {
  active: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string
};

export default InputPassword;
