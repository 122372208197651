import React from 'react';
import PropTypes from 'prop-types';
import { VictoryChart, VictoryLine, VictoryScatter, VictoryAxis } from 'victory';
import * as Styled from './styled';

import FaceIcon from './components/FaceIcon';

const StatisticsWeek = ({ data, label }) => {
  if (data.length === 0) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <Styled.Label>{label}</Styled.Label>
      <Styled.StatisticBox>
        <VictoryChart padding={{ top: 24, bottom: 60, left: 24, right: 24 }}>
          <VictoryAxis
            data={data}
            style={{
              axis: { stroke: '#ffffff' },
              grid: { stroke: '#F6F6F6' },
              ticks: { stroke: '#F6F6F6', size: 15, padding: 7 },
              tickLabels: {
                fontSize: 20,
                fontFamily: 'inherit',
                color: '#5C5C5C',
                fill: 'inherit'
              }
            }}
          />
          <VictoryLine
            domain={{ x: [1, 6], y: [1, 4] }}
            height={300}
            data={data}
            style={{ data: { stroke: '#F5C9BB' } }}
          />
          <VictoryScatter dataComponent={<FaceIcon />} data={data} />
        </VictoryChart>
      </Styled.StatisticBox>
    </Styled.Wrapper>
  );
};

StatisticsWeek.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string
};

export default StatisticsWeek;
