import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  overflow: hidden;
`;

export const StepperWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: nowrap row;
  justify-content: ${({ position }) => (position ? 'flex-start' : 'center')};
  left: calc((-${({ step }) => step} + 3) * 85px);
  width: 100%;

  @media (max-width: 320px) {
    > *:nth-child(n + ${({ step }) => step + 1}) {
      opacity: 0;
    }
  }

  > *:nth-child(n + ${({ step }) => step + 2}) {
    opacity: 0;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;

  font-size: 16px;
  line-height: 17px;
  color: ${Color.gray_4};
  background-color: ${Color.gray_1};
  font-weight: ${FontWeight.Normal};
`;

export const StepActive = styled(Step)`
  background-color: ${Color.primaryBrand};
  color: ${Color.white};
`;

export const StepDone = styled(Step)`
  background-color: ${Color.primaryBrand};
`;

export const Icon = styled.img`
  display: block;
`;

export const Stepper = styled.div`
  position: relative;

  ${({ line, colorLine }) =>
    line
      ? `
      padding-right: 50px;
      
      &:after {
        content: '';
        position: absolute;
        top: 17px;
        right: 8px;
        width: 34px;
        height: 1px;
        background-color: ${colorLine ? Color.primaryBrand : Color.gray_1};
        transition: all 0.3s ease;
      }
  `
      : null};
`;
