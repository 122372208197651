import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const ToggleSwitch = ({ checked, onChange }) => (
  <Styled.Wrapper>
    <input type="checkbox" checked={checked} onChange={onChange} />

    <Styled.Switch>
      <Styled.Circle />
    </Styled.Switch>
  </Styled.Wrapper>
);

ToggleSwitch.defaultProps = {
  checked: false
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default ToggleSwitch;
