export const API = {
  WP_URL: 'https://wp.humanpower.kyotu.tech/wp-json/humanpower/v1',
  SYMFONY_URL: 'https://api.wellq.pl/api/'
};

export const MOOD_TYPE = {
  AVERAGE: 'average',
  BAD: 'bad',
  GOOD: 'good',
  GREAT: 'great',
  SAD: 'sad'
};

export const BACKGROUND_TYPE = {
  ACCOUNT_DETAILS: 'backgroundAccountDetails',
  ACTIVE_PROGRAM: 'backgroundActiveProgram',
  CHANGE_PASSWORD: 'backgroundChangePassword',
  CHANGE_PASSWORD_CONFIRM: 'backgroundChangePasswordConfirm',
  CODE_NOT_WORK: 'backgroundCodeNotWork',
  DELETE_ACCOUNT: 'backgroundDeleteAccount',
  DELETE_ACCOUNT_CONFIRM: 'backgroundDeleteAccountConfirm',
  FEED_BACK_1: 'backgroundFeedBack1',
  FEED_BACK_2: 'backgroundFeedBack2',
  FEED_BACK_3: 'backgroundFeedBack3',
  FORGOT_PASSWORD: 'backgroundForgotPassword',
  FORGOT_PASSWORD_CONFIRM: 'backgroundForgotPasswordConfirm',
  NOTIFICATIONS: 'backgroundNotifications',
  NOTIFICATIONS_EDIT: 'backgroundNotificationsEdit',
  POWER_AUDIT: 'backgroundPowerAudit',
  PROGRAMME_ATTITUDE: 'backgroundProgrammeAttitude',
  REGISTER: 'backgroundRegister',
  SING_IN: 'backgroundSingIn',
  SING_UP: 'backgroundSingUp',
  START_PAGE_1: 'backgroundStartPage1',
  START_PAGE_2: 'backgroundStartPage2',
  START_PAGE_3: 'backgroundStartPage3',
  TASK_SUMMARY_BOTTOM: 'backgroundTaskSummaryBottom',
  TASK_SUMMARY_TOP: 'backgroundTaskSummaryTop',
  REPORT_PROBLEM_SUCCESS: 'backgroundReportProblemSuccess',
  USER_DETAILS: 'backgroundUserDetails'
};

export const ROUTE = {
  ACCOUNT_DETAILS: '/account-details',
  BODY: '/category/body',
  CHANGE_PASSWORD: '/account-details/change-password',
  CHANGE_PASSWORD_CONFIRM: '/account-details/change-password-confirm',
  CODE_NOT_WORK: '/code-not-work',
  CONTACT: '/contact',
  DASHBOARD: '/dashboard',
  DELETE_ACCOUNT: '/account-details/delete-account',
  DELETE_ACCOUNT_CONFIRM: '/account-details/delete-account-confirm',
  FAQ: '/faq',
  FEEDBACK: '/feedback',
  FORGOT_PASSWORD_SUCCESS: '/forgot-password-success',
  FORGOT_PASSWORD: '/forgot-password',
  LOG_OUT_CONFIRM: '/log-out',
  MENU: '/menu',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_EDIT: '/notifications-edit',
  ORGANIZATION_DETAILS: '/organization-details',
  PRIVACY_POLICY: '/privacy-policy',
  PROGRAMME_ATTITUDE: 'mood',
  PROGRAM: '/program',
  PROFILE: '/profile',
  REGULATIONS: '/regulations',
  REPORT_PROBLEM: '/report-problem',
  REPORT_PROBLEM_SUCCESS: '/report-problem-success',
  CATEGORIES: '/categories',
  CATEGORY: '/category',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  INSTRUCTION: '/instruction',
  START_PAGE: '/start-page',
  TASK: '/task',
  USER_DETAILS: '/user-details',
  USER_DETAILS_EDIT: '/user-details-edit'
};

export const MENU = {
  GENERAL: [
    { name: 'Szczegóły konta', path: ROUTE.ACCOUNT_DETAILS },
    { name: 'Informacje o organizacji', path: ROUTE.ORGANIZATION_DETAILS },
    { name: 'Pobierz aplikacje', path: ROUTE.INSTRUCTION },
    { name: 'FAQ', path: ROUTE.FAQ },
    { name: 'Polityka prywatności', path: ROUTE.PRIVACY_POLICY },
    { name: 'Regulamin', path: ROUTE.REGULATIONS },
    { name: 'Kontakt', path: ROUTE.CONTACT }
  ],
  ACCOUNT_DETAILS: [
    { name: 'Zmiana hasła', path: ROUTE.CHANGE_PASSWORD },
    { name: 'Powiadomienia', path: ROUTE.NOTIFICATIONS },
    { name: 'Dane użytkownika', path: ROUTE.USER_DETAILS },
    { name: 'Usunięcie konta', path: ROUTE.DELETE_ACCOUNT }
  ]
};

export const QUERY_STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success'
};

export const TASK_STATUS = {
  WAITING: 'waiting',
  SKIPPED: 'skipped',
  DONE: 'done'
};

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other'
};

export const WEEK_LABEL = {
  MONDAY: 'Pn',
  TUESDAY: 'Wt',
  WEDNESDAY: 'Śr',
  THURSDAY: 'Czw',
  FRIDAY: 'Pt',
  SATURDAY: 'Sb',
  SUNDAY: 'Ndz'
};

export const JUSTIFY = {
  SPACE_AROUND: 'space-around',
  SPACE_BETWEEN: 'space-between'
};
