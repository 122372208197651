import styled from 'styled-components';
import { Color } from '../../../../../../theme/_color';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: ${Color.white};
  z-index: 999;
`;
