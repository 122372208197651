import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const ButtonBorder = ({ active, handleClick, label, type, dataValue }) => (
  <Styled.Wrapper type={type} active={active} onClick={handleClick} data-value={dataValue}>
    {label}
  </Styled.Wrapper>
);

ButtonBorder.defaultProps = {
  active: false,
  type: 'button',
  dataValue: ''
};

ButtonBorder.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  dataValue: PropTypes.string
};

export default ButtonBorder;
