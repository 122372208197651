import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';
import { H4 } from '../../theme/_typography';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin-bottom: 24px;
`;

export const WrapperInformation = styled.div`
  display: flex;
  flex-flow: wrap column;
  padding-left: 16px;
  max-width: calc(100% - 113px);
`;

export const Poster = styled.div`
  position: relative;
  display: flex;
  width: 113px;
  height: 68px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  overflow: hidden;
`;

export const Progress = styled.div`
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding-top: 6px;
  background: ${Color.white};
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  height: 3px;
  width: 100%;
  background: ${Color.gray_1};

  &:after {
    content: '';
    display: block;
    background: ${Color.secondaryGreen};
    width: ${({ progress }) => progress}%;
    height: 100%;
  }
`;

export const ProgressDesc = styled.div`
  display: block;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 3px;
  text-align: center;
  width: 100%;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
`;

export const Title = styled(H4)`
  width: 100%;
  margin: 6px 0 0;
  color: ${Color.neutralBlack};
`;

export const Details = styled.div`
  display: flex;
  flew-flow: wrap row;
  width: 100%;
`;

export const Icon = styled.img`
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
`;

export const Days = styled.span`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-right: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray_4};
  font-weight: ${FontWeight.Normal};
`;

export const Category = styled.span`
  position: relative;
  display: block;
  margin-top: 5px;
  padding-left: 13px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray_4};
  font-weight: ${FontWeight.Normal};

  &:first-letter {
    text-transform: uppercase;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${Color.gray_2};
  }
`;

export const Desc = styled.div`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.neutralBlack};
`;
