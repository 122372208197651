import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconCheck from '../../images/icon-check.svg';

const Checkbox = ({ error, value, children, name, onChange }) => (
  <Styled.Checkbox>
    <Styled.Wrapper>
      <input value={value} type="checkbox" onChange={onChange} name={name} />
      <Styled.Check error={error}>
        <Styled.Img src={iconCheck} />
      </Styled.Check>
      <Styled.Text>{children}</Styled.Text>
    </Styled.Wrapper>

    {error ? <Styled.Error>{error}</Styled.Error> : null}
  </Styled.Checkbox>
);

Checkbox.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node
};

export default Checkbox;
