import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const MenuList = ({ items, margin }) => {
  const renderMenu = items.map((item, key) => {
    const { name, path } = item;

    return (
      <Styled.Item key={key} to={path}>
        {name}
      </Styled.Item>
    );
  });

  return <Styled.Wrapper margin={margin}>{renderMenu}</Styled.Wrapper>;
};

MenuList.defaultProps = {
  margin: '0'
};

MenuList.propTypes = {
  margin: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default MenuList;
