import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundForgotPasswordConfirm = () => (
  <Styled.Wrapper>
    <Image
      src={IMAGES.forgotPasswordConfirm_1}
      top={0}
      right={0}
      width={158}
      height={188}
      index={1}
    />
    <Image
      src={IMAGES.forgotPasswordConfirm_2}
      top={156}
      right={108}
      width={137}
      height={150}
      index={1}
    />
    <Image
      src={IMAGES.forgotPasswordConfirm_3}
      top={111}
      left={0}
      width={375}
      height={166}
      index={1}
    />
    <Image
      src={IMAGES.forgotPasswordConfirm_4}
      top={0}
      left={0}
      width={204}
      height={314}
      index={5}
    />
    <Image
      src={IMAGES.forgotPasswordConfirm_5}
      top={0}
      left={0}
      width={163}
      height={179}
      index={1}
    />
  </Styled.Wrapper>
);

export default BackgroundForgotPasswordConfirm;
