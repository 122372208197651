import React from 'react';
import PropTypes from 'prop-types';
import { BACKGROUND_TYPE } from '../../constants';
import {
  BackgroundAccountDetails,
  BackgroundActiveProgram,
  BackgroundChangePassword,
  BackgroundChangePasswordConfirm,
  BackgroundCodeNotWork,
  BackgroundDeleteAccount,
  BackgroundDeleteAccountConfirm,
  BackgroundFeedBack1,
  BackgroundFeedBack2,
  BackgroundFeedBack3,
  BackgroundForgotPassword,
  BackgroundForgotPasswordConfirm,
  BackgroundNotifications,
  BackgroundNotificationsEdit,
  BackgroundPowerAudit,
  BackgroundProgrammeAttitude,
  BackgroundRegister,
  BackgroundReportProblemSuccess,
  BackgroundSingIn,
  BackgroundSingUp,
  BackgroundStartPage1,
  BackgroundStartPage2,
  BackgroundStartPage3,
  BackgroundTaskSummaryBottom,
  BackgroundTaskSummaryTop,
  BackgroundUserDetails
} from './components';

const Background = ({ type }) => {
  switch (type) {
    case BACKGROUND_TYPE.ACCOUNT_DETAILS: {
      return <BackgroundAccountDetails />;
    }
    case BACKGROUND_TYPE.ACTIVE_PROGRAM: {
      return <BackgroundActiveProgram />;
    }
    case BACKGROUND_TYPE.CHANGE_PASSWORD: {
      return <BackgroundChangePassword />;
    }
    case BACKGROUND_TYPE.CHANGE_PASSWORD_CONFIRM: {
      return <BackgroundChangePasswordConfirm />;
    }
    case BACKGROUND_TYPE.CODE_NOT_WORK: {
      return <BackgroundCodeNotWork />;
    }
    case BACKGROUND_TYPE.DELETE_ACCOUNT: {
      return <BackgroundDeleteAccount />;
    }
    case BACKGROUND_TYPE.DELETE_ACCOUNT_CONFIRM: {
      return <BackgroundDeleteAccountConfirm />;
    }
    case BACKGROUND_TYPE.FEED_BACK_1: {
      return <BackgroundFeedBack1 />;
    }
    case BACKGROUND_TYPE.FEED_BACK_2: {
      return <BackgroundFeedBack2 />;
    }
    case BACKGROUND_TYPE.FEED_BACK_3: {
      return <BackgroundFeedBack3 />;
    }
    case BACKGROUND_TYPE.FORGOT_PASSWORD: {
      return <BackgroundForgotPassword />;
    }
    case BACKGROUND_TYPE.FORGOT_PASSWORD_CONFIRM: {
      return <BackgroundForgotPasswordConfirm />;
    }
    case BACKGROUND_TYPE.SING_IN: {
      return <BackgroundSingIn />;
    }
    case BACKGROUND_TYPE.SING_UP: {
      return <BackgroundSingUp />;
    }
    case BACKGROUND_TYPE.NOTIFICATIONS: {
      return <BackgroundNotifications />;
    }
    case BACKGROUND_TYPE.NOTIFICATIONS_EDIT: {
      return <BackgroundNotificationsEdit />;
    }
    case BACKGROUND_TYPE.POWER_AUDIT: {
      return <BackgroundPowerAudit />;
    }
    case BACKGROUND_TYPE.PROGRAMME_ATTITUDE: {
      return <BackgroundProgrammeAttitude />;
    }
    case BACKGROUND_TYPE.REGISTER: {
      return <BackgroundRegister />;
    }
    case BACKGROUND_TYPE.REPORT_PROBLEM_SUCCESS: {
      return <BackgroundReportProblemSuccess />;
    }
    case BACKGROUND_TYPE.START_PAGE_1: {
      return <BackgroundStartPage1 />;
    }
    case BACKGROUND_TYPE.START_PAGE_2: {
      return <BackgroundStartPage2 />;
    }
    case BACKGROUND_TYPE.START_PAGE_3: {
      return <BackgroundStartPage3 />;
    }
    case BACKGROUND_TYPE.TASK_SUMMARY_BOTTOM: {
      return <BackgroundTaskSummaryBottom />;
    }
    case BACKGROUND_TYPE.TASK_SUMMARY_TOP: {
      return <BackgroundTaskSummaryTop />;
    }
    case BACKGROUND_TYPE.USER_DETAILS: {
      return <BackgroundUserDetails />;
    }

    default: {
      return null;
    }
  }
};

Background.propTypes = {
  type: PropTypes.string.isRequired
};

export default Background;
