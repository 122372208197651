import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';
import * as Styled from './styled';

import { ReactComponent as IconCaretDown } from '../../images/icon-caret-down.svg';

const CustomSelect = ({ error, onChange, value, name, options, placeholder }) => {
  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconCaretDown />
    </components.DropdownIndicator>
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: 'none',
      padding: '16px 0',
      color: Color.black,
      fontWeight: state.isSelected ? FontWeight.Bold : FontWeight.Normal,
      backgroundColor: Color.white
    }),
    control: (base, state) => {
      const focusBorder = state.menuIsOpen
        ? `2px solid ${Color.primaryBrand}`
        : `1px solid ${Color.gray_1}`;

      const errorBorder = state.menuIsOpen
        ? `2px solid ${Color.primaryBrand}`
        : `2px solid ${Color.error}`;

      return {
        ...base,
        border: error ? errorBorder : focusBorder,
        height: 48,
        borderRadius: '10px',
        padding: state.menuIsOpen || error ? '0 19px 0 23px' : '0 20px 0 24px',
        boxShadow: 'none',
        '&:hover': {
          border: error ? `2px solid ${Color.error}` : focusBorder
        },
        svg: {
          transform: state.menuIsOpen ? 'rotateX(180deg)' : 'rotateX(0)',
          transition: 'all 100ms ease',
          path: {
            fill: state.menuIsOpen ? Color.primaryBrand : Color.gray_1,
            transition: 'all 100ms ease'
          }
        }
      };
    },
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: () => ({
      padding: 0
    }),
    valueContainer: () => ({
      display: 'flex',
      alignItems: 'center',
      margin: 0
    }),
    singleValue: () => ({
      margin: 0,
      color: Color.black
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '16px',
      color: Color.gray_4,
      fontWeight: 400,
      margin: 0
    }),
    menu: (base) => ({
      ...base,
      margin: '2px 0 0',
      padding: '8px 24px',
      borderRadius: '10px',
      boxShadow: '0px 4px 16px rgba(122, 122, 122, 0.25)',
      overflow: 'hidden',
      zIndex: 999
    })
  };

  return (
    <Styled.Wrapper>
      <Select
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        value={value}
        name={name}
        components={{ DropdownIndicator }}
        styles={customStyles}
        isSearchable={false}
      />

      {error ? <Styled.Error>{error}</Styled.Error> : null}
    </Styled.Wrapper>
  );
};

CustomSelect.defaultProps = {
  options: []
};

CustomSelect.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array
};

export default CustomSelect;
