import { defineMessages } from 'react-intl';

export const scope = 'app.components.LogOut';

export default defineMessages({
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Wyloguj'
  }
});
