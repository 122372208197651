import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const NumberList = ({ items }) => {
  const renderItems = items.map((item, index) => (
    <Styled.Item key={index} number={index + 1}>
      {item}
    </Styled.Item>
  ));

  return <Styled.Wrapper>{renderItems}</Styled.Wrapper>;
};

NumberList.defaultProps = {
  items: []
};

NumberList.propTypes = {
  items: PropTypes.array
};

export default NumberList;
