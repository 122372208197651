import React from 'react';
import PropTypes from 'prop-types';
import { Color } from '../../theme/_color';
import * as Styled from './styled';

const ButtonWithIcon = ({ color, handleClick, icon, text }) => (
  <Styled.Wrapper color={color} onClick={handleClick}>
    <Styled.Icon src={icon} />
    <Styled.Text>{text}</Styled.Text>
  </Styled.Wrapper>
);

ButtonWithIcon.defaultProps = {
  color: Color.white,
  icon: '',
  text: ''
};

ButtonWithIcon.propTypes = {
  color: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  text: PropTypes.object
};

export default ButtonWithIcon;
