import styled from 'styled-components';
import { FontWeight } from './_fonts';

export const H1 = styled.h1`
  font-size: 32px;
  line-height: 38px;
  font-weight: ${FontWeight.Bold};
  margin: 0;
`;

export const H2 = styled.h2`
  font-size: 24px;
  line-height: 28px;
  font-weight: ${FontWeight.Bold};
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Bold};
  margin: 0;
`;

export const H4 = styled.h4`
  font-size: 16px;
  line-height: 18px;
  font-weight: ${FontWeight.Bold};
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: ${FontWeight.Normal};
  margin: 0;
`;
