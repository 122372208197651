import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
  width: 100%;
`;

export const Image = styled.img`
  display: block;
`;
