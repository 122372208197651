import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Poster = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 66px;
  height: 39px;
  margin-right: 22px;
  border-radius: 5px;
  overflow: hidden;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  max-width: calc(100% - 88px);
`;

export const Days = styled.div`
  display: block;
  font-size: 12px;
  font-weight: ${FontWeight.Normal};
  line-height: 14px;
  color: ${Color.gray_3};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Title = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin: 4px 0 0;
  padding-left: ${({ padding }) => padding && '17px'};
  font-size: 14px;
  line-height: 16px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.neutralBlack};
`;

export const Icon = styled.img`
  position: absolute;
  top: 2px;
  left: 0;
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
`;

export const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: ${Color.black};

  &:visited {
    color: ${Color.black};
  }
`;
