import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;

  &:not(:last-of-type) {
    margin: 0 0 24px 0;
  }
`;

export const Error = styled.div`
  display: block;
  width: 100%;
  margin-top: 6px;
  font-size: 10px;
  line-height: 12px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.error};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  border: ${({ error }) => (error ? `2px solid ${Color.error}` : `1px solid ${Color.gray_1}`)};
  border-radius: 10px;
  overflow: hidden;

  &:focus-within {
    border: 2px solid ${Color.primaryBrand};
  }
`;

export const ShowPassword = styled.div`
  position: absolute;
  top: 0;
  right: 4px;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  z-index: 10;
`;

export const Input = styled.input`
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${({ error }) => (error ? '0 58px 0 23px' : '0 58px 0 24px')};
  border: 0;
  border-radius: 10px;
  font-size: 16px;
  line-height: 17px;
  font-family: 'DM Sans', sans-serif;
  color: ${Color.neutralBlack};
  font-weight: ${FontWeight.Normal};

  &:focus {
    padding: 0 58px 0 23px;

    + ${ShowPassword} {
      top: -1px;
      right: 3px;
    }
  }
`;

export const Image = styled.img`
  display: block;
  width: 24px;
  height: 18px;
`;

export const ImageHidden = styled.img`
  display: block;
  width: 30px;
  height: 28px;
`;
