import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Dashboard';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Cześć {name}!'
  },
  subtitle1: {
    id: `${scope}.subtitle1`,
    defaultMessage: 'Jak się dziś czujesz?'
  },
  subtitle2: {
    id: `${scope}.subtitle2`,
    defaultMessage: 'Wybrane dla Ciebie'
  },
  text: {
    id: `${scope}.text`,
    defaultMessage:
      'Tutaj zobaczysz swoją aktywną ścieżkę wellbeingową. Pojawi się, gdy zaczniesz działać.'
  },
  bad: {
    id: `${scope}.bad`,
    defaultMessage: 'Złe'
  },
  average: {
    id: `${scope}.average`,
    defaultMessage: 'Średnie'
  },
  good: {
    id: `${scope}.good`,
    defaultMessage: 'Dobre'
  },
  great: {
    id: `${scope}.great`,
    defaultMessage: 'Świetnie'
  }
});
