import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getAuthorizationToken,
  getRegistrationToken,
  removeRegistrationToken,
  setAuthorizationToken as setAuthorizationTokenInLocalStorage,
  setRegistrationToken as setRegistrationTokenInLocalStorage
} from '../utils/authorization.utils';
import { setStartPageStatus, getStartPageStatus } from '../utils/startPage.utils';

export const AuthorizationContext = createContext();

export const AuthorizationContextProvider = ({ children }) => {
  const [authorizationToken, setAuthorizationToken] = useState(getAuthorizationToken());
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [registrationStep, setRegistrationStep] = useState(1);
  const [registrationToken, setRegistrationToken] = useState(getRegistrationToken());
  const [startPage, setStartPage] = useState(getStartPageStatus());
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    if (authorizationToken) {
      setAuthorizationTokenInLocalStorage(authorizationToken);
    }
  }, [authorizationToken]);

  useEffect(() => {
    if (startPage) {
      setStartPageStatus(startPage);
    }
  }, [startPage]);

  useEffect(() => {
    if (registrationToken) {
      setRegistrationTokenInLocalStorage(registrationToken);
      return;
    }

    removeRegistrationToken();
  }, [registrationToken]);

  return (
    <AuthorizationContext.Provider
      value={{
        setIsLogin,
        isLogin,
        authorizationToken,
        isRegistered,
        registrationStep,
        registrationToken,
        setAuthorizationToken,
        setIsRegistered,
        setRegistrationStep,
        setRegistrationToken,
        setStartPage,
        startPage,
        formData,
        setFormData
      }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

AuthorizationContextProvider.propTypes = {
  children: PropTypes.node
};
