import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundUserDetails = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.userDetails_1} top="117" right="43" width={119} height={125} index={2} />
    <Image src={IMAGES.userDetails_2} top="0" right="0" width={186} height={313} index={1} />
    <Image src={IMAGES.userDetails_3} top="196" left="0" width={213} height={117} index={3} />
    <Image src={IMAGES.userDetails_4} top="42" right="0" width={375} height={271} index={5} />
    <Image src={IMAGES.userDetails_5} top="107" right="0" width={173} height={206} index={4} />
  </Styled.Wrapper>
);

export default BackgroundUserDetails;
