import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';
import { Paragraph } from '../../theme/_typography';

export const Desc = styled(Paragraph)`
  width: 100%;
  margin: 0 0 19px;
  color: ${Color.black};
`;

export const Title = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.black};
`;

export const WrapperSlider = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`;
