import React from 'react';
import { Image, ImageUniversal } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundStartPage2 = () => (
  <Styled.Wrapper>
    <ImageUniversal
      src={IMAGES.startPage_3}
      top="120px"
      left="50%"
      width={173}
      height={27}
      translate="-50%, 0"
      index={3}
    />
    <Image src={IMAGES.startPage_9} top={118} right={0} width={331} height={384} index={12} />
    <Image src={IMAGES.startPage_10} top={151} right={0} width={165} height={300} index={2} />
    <Image src={IMAGES.startPage_11} top={0} left={0} width={375} height={84} index={2} />
    <Image src={IMAGES.startPage_12} top={0} left={0} width={264} height={121} index={2} />
    <Image src={IMAGES.startPage_13} top={167} left={0} width={152} height={277} index={2} />
  </Styled.Wrapper>
);

export default BackgroundStartPage2;
