import React from 'react';
import PropTypes from 'prop-types';

import icon_1 from '../../../../images/icon-mood-0.svg';
import icon_2 from '../../../../images/icon-mood-1.svg';
import icon_3 from '../../../../images/icon-mood-2.svg';
import icon_4 from '../../../../images/icon-mood-3.svg';
import icon_5 from '../../../../images/icon-mood-4.svg';

const FaceIcon = ({ x, y, datum }) => {
  const icon = (number) => {
    switch (number) {
      case 1: {
        return icon_1;
      }
      case 2: {
        return icon_2;
      }
      case 3: {
        return icon_3;
      }
      case 4: {
        return icon_4;
      }
      case 5: {
        return icon_5;
      }
      default: {
        return null;
      }
    }
  };

  return <image href={icon(datum._y)} height="46px" width="46px" x={x - 23} y={y - 23} />;
};

FaceIcon.propTypes = {
  x: PropTypes.any,
  y: PropTypes.any,
  datum: PropTypes.any
};

export default FaceIcon;
