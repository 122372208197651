import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundSingUp = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.singUp_1} top={0} left={0} width={271} height={334} index={6} />
    <Image src={IMAGES.singUp_2} top={58} right={0} width={144} height={288} index={2} />
    <Image src={IMAGES.singUp_3} top={0} right={0} width={219} height={141} index={3} />
    <Image src={IMAGES.singUp_4} top={171} left={0} width={155} height={176} index={4} />
    <Image src={IMAGES.singUp_5} top={70} right={0} width={67} height={398} index={5} />
    <Image src={IMAGES.singUp_6} top={0} left={0} width={181} height={143} index={1} />
  </Styled.Wrapper>
);

export default BackgroundSingUp;
