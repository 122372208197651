import styled from 'styled-components';

export const Hamburger = styled.div`
  position: absolute;
  top: 37px;
  right: 24px;
  z-index: 99;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
`;

export const Line = styled.img`
  display: block;
  width: 28px;
  height: 4px;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;
