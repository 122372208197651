import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap row;
  background-color: transparent;
  min-width: 158px;
  min-height: 48px;
  border-radius: 10px;
  border: 2px solid;
  font-size: 18px;
  line-height: 23px;

  ${({ active }) =>
    active
      ? `
    font-weight: ${FontWeight.Bold};
    color: ${Color.neutralBlack};
    border-color: ${Color.primaryBrand};
  `
      : `
    font-weight: ${FontWeight.Normal};
    color: ${Color.gray_4};
    border-color: ${Color.gray_4};
  `}
`;
