import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const RatingNumbers = ({ count, onChange, textLeft, textRight, value }) => {
  const numbers = Array.from({ length: count }, () => '');

  const handleChange = (value) => {
    onChange(value + 1);
  };

  const renderNumbers = numbers.map((number, i) => (
    <Styled.Number active={i + 1 === value} key={i} onClick={() => handleChange(i)}>
      {i + 1}
    </Styled.Number>
  ));

  return (
    <Styled.Wrapper>
      <Styled.Numbers>{renderNumbers}</Styled.Numbers>
      {textLeft || textRight ? (
        <Styled.Signatures>
          <Styled.Text>{textLeft}</Styled.Text>
          <Styled.Text>{textRight}</Styled.Text>
        </Styled.Signatures>
      ) : null}
    </Styled.Wrapper>
  );
};

RatingNumbers.defaultProps = {
  textLeft: null,
  textRight: null
};

RatingNumbers.propTypes = {
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  textLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  textRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.number.isRequired
};

export default RatingNumbers;
