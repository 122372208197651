import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const TextArea = ({ placeholder, name, value, onChange, error }) => (
  <Styled.Wrapper>
    <Styled.Textarea
      error={error}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
    <Styled.Error>{error}</Styled.Error>
  </Styled.Wrapper>
);

TextArea.defaultProps = {
  placeholder: ''
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string
};

export default TextArea;
