import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin: 0 0 24px 0;
`;

export const Input = styled.input`
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: ${({ error }) => (error ? `2px solid ${Color.error}` : `1px solid ${Color.gray_1}`)};
  padding: ${({ error }) => (error ? '0 23px' : '0 24px')};
  margin: 0;
  font-size: 16px;
  line-height: 17px;
  font-family: 'DM Sans', sans-serif;
  color: ${Color.neutralBlack};
  font-weight: ${FontWeight.Normal};

  &:focus {
    padding: 0 23px;
    border: 2px solid ${Color.primaryBrand};
  }
`;

export const Error = styled.div`
  display: block;
  width: 100%;
  margin-top: 6px;
  font-size: 10px;
  line-height: 12px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.error};
`;
