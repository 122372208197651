import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding: 14px 0 14px 0;
  border-top: 2px solid ${Color.gray_1};

  &:last-child {
    border-bottom: 2px solid ${Color.gray_1};
  }
`;

export const Title = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  min-height: 66px;
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Medium};
  width: 100%;
  padding: 0 88px 0 24px;
`;

export const Description = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding-left: 24px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 19px;
  color: ${Color.black};
  white-space: pre-line;
  overflow: hidden;

  padding-top: ${({ active }) => (active ? '10px' : '0')};
  max-height: ${({ active }) => (active ? '9999px' : '0')};
  transition: padding-top ${({ active }) => (active ? '0.1s' : '0.5s')},
    max-height 0.4s cubic-bezier(0, 1, 0, 1) -0.1s;
  ${({ active }) =>
    active &&
    `
      transition-timing-function: cubic-bezier(0.5, 0, 1, 0); 
      transition-delay: 0s;
    `};
`;

export const Icon = styled.div`
  position: absolute;
  top: 19px;
  right: 31px;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0 0 9px rgba(128, 64, 255, 0.21);

  img {
    display: block;
    max-width: 100%;
    transform: rotateX(${({ rotate }) => (rotate ? '180deg' : '0')});
    transition: all 0.3s ease;
  }
`;
