import axios from 'axios';
import { getAuthorizationToken } from '../../utils/authorization.utils';
import { onRequestRejected } from '../repository.helpers';

const { REACT_APP_SYMFONY_API_URL } = process.env;

const Repository = axios.create({
  baseURL: REACT_APP_SYMFONY_API_URL
});

Repository.interceptors.request.use((config) => {
  const token = getAuthorizationToken();

  if (!token) {
    return config;
  }

  const headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*'
  };

  return {
    ...config,
    headers
  };
});

Repository.interceptors.response.use((response) => Promise.resolve(response), onRequestRejected);

export default Repository;
