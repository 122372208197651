import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconStar from '../../images/icon-star.svg';
import iconStarOutline from '../../images/icon-star-outline.svg';

const Star = ({ marked, id }) => (
  <Styled.Wrapper>
    <Styled.Image data-star-id={id} src={marked ? iconStar : iconStarOutline} />
  </Styled.Wrapper>
);

Star.propTypes = {
  marked: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired
};

export default Star;
