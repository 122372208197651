import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconCheck from '../../images/icon-check-primary.svg';

const ButtonCheck = ({ active, onClick, label }) => (
  <Styled.Button active={active} onClick={onClick}>
    {label}
    {active && <Styled.Icon src={iconCheck} />}
  </Styled.Button>
);

ButtonCheck.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string
};

export default ButtonCheck;
