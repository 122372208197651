import styled from 'styled-components';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap column;
  justify-content: center;
  align-items: center;
  min-width: 112px;
  min-height: 58px;
  max-width: 153px;
  max-height: 112px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid ${Color.gray_2};
  overflow: hidden;
`;

export const Image = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
`;
