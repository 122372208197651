import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const LogoBlockBorder = ({ src }) => (
  <Styled.Wrapper>
    <Styled.Image src={src} />
  </Styled.Wrapper>
);

LogoBlockBorder.propTypes = {
  src: PropTypes.string.isRequired
};

export default LogoBlockBorder;
