import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  height: auto;
`;

export const Textarea = styled.textarea`
  height: 260px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 10px;
  border: ${({ error }) => (error ? `2px solid ${Color.error}` : `1px solid ${Color.gray_1}`)};
  resize: none;
  color: ${Color.gray_4};

  &:focus {
    border: 2px solid ${Color.primaryBrand};
    padding: 15px 23px;
  }
`;

export const Error = styled.div`
  display: block;
  width: 100%;
  margin-top: 6px;
  font-size: 10px;
  line-height: 12px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.error};
`;
