import { always, cond, equals } from 'ramda';
import { DATA_TYPE } from './nativeBridge.constants';
import NativeTokenFunctionHolder from '../hooks/useNativeToken/nativeTokenFunctionHolder';

const handleReceivingNotificationToken = (payload) => {
  const { token } = payload;
  const setNativeToken = NativeTokenFunctionHolder.getFunction();
  if (setNativeToken instanceof Function) {
    setNativeToken(token);
  }
};

const callBridgeDataHandler = cond([
  [equals(DATA_TYPE.NOTIFICATION_TOKEN), always(handleReceivingNotificationToken)]
]);

const onBridgeData = (dataType, dataString) => {
  console.log(`dataType: ${dataType}, dataString: ${dataString}`);
  try {
    const data = JSON.parse(dataString);
    callBridgeDataHandler(dataType)(data);
  } catch (error) {
    console.error('Error while parsing native request', error);
  }
};

window.onBridgeData = onBridgeData;
