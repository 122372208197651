import styled, { keyframes } from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

const fade = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

export const TabsPath = styled.li`
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: -2px;
  ${({ active }) =>
    active
      ? `
        font-weight: ${FontWeight.Bold};
        color: ${Color.primaryBrand};
        border-bottom: 2px solid ${Color.primaryBrand};
      `
      : `
        font-weight: ${FontWeight.Normal};
        color: ${Color.gray_4};
    `}
`;

export const NavTabs = styled.ul`
  display: flex;
  justify-content: ${({ justify }) => justify};
  border-bottom: 2px solid ${Color.gray_1};
  list-style: none;
  padding: 0 17px;
  margin: 0;
`;

export const Tabs = styled.div`
  display: block;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
`;

export const TabContent = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin: 0;
`;

export const Content = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  width: 100%;
  animation: ${fade} 0.3s linear;
`;
