import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../constants';
import * as Styled from './styled';

import iconLine from '../../images/icon-line.svg';

const Hamburger = () => (
  <Styled.Hamburger>
    <Link to={ROUTE.MENU} />
    <Styled.Line src={iconLine} />
    <Styled.Line src={iconLine} />
    <Styled.Line src={iconLine} />
  </Styled.Hamburger>
);

export default Hamburger;
