import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  width: 100%;
`;

export const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-weight: ${({ active }) => (active ? FontWeight.Bold : FontWeight.Normal)};
  border: 2px solid ${({ active }) => (active ? Color.primaryBrand : Color.gray_4)};
`;

export const Numbers = styled.div`
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
  width: 100%;
`;

export const Signatures = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

export const Text = styled.span`
  display: block;
  width: 46px;
  font-size: 14px;
  line-height: 19px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.black};
  text-align: center;
`;
