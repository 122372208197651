import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Star from '../Star';
import * as Styled from './styled';

const RatingStars = ({ value, handleClick, handleRating }) => {
  const [rating, setRating] = useState(parseInt(value) || 0);
  const [selection, setSelection] = useState(0);

  const hoverOver = (event) => {
    let val = 0;
    if (event && event.target && event.target.getAttribute('data-star-id'))
      val = event.target.getAttribute('data-star-id');
    setSelection(val);
  };

  const eventClick = (e) => {
    const value = e.target.getAttribute('data-star-id') || rating;
    setRating(value);
    handleRating(value);

    if (selection >= 1) {
      handleClick();
    }
  };

  return (
    <Styled.Wrapper onMouseOut={() => hoverOver(null)} onClick={eventClick} onMouseOver={hoverOver}>
      {Array.from({ length: 5 }, (v, i) => (
        <Star
          id={i + 1}
          key={`star_${i + 1}`}
          marked={selection ? selection >= i + 1 : rating >= i + 1}
        />
      ))}
    </Styled.Wrapper>
  );
};

RatingStars.propTypes = {
  value: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleRating: PropTypes.func.isRequired
};

export default RatingStars;
