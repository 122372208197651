import React from 'react';
import PropTypes from 'prop-types';
import { Color } from '../../theme/_color';
import { MOOD_TYPE } from '../../constants';
import * as Styled from './styled';

import iconAverage from '../../images/mood/average.svg';
import iconBad from '../../images/mood/bad.svg';
import iconGood from '../../images/mood/good.svg';
import iconGreat from '../../images/mood/great.svg';
import iconSad from '../../images/mood/sad.svg';

const Mood = ({ active, color, label, name, onChange, type, value }) => {
  const renderImage = (param) => {
    switch (param) {
      case MOOD_TYPE.AVERAGE: {
        return <Styled.Icon src={iconAverage} />;
      }
      case MOOD_TYPE.BAD: {
        return <Styled.Icon src={iconBad} />;
      }
      case MOOD_TYPE.GOOD: {
        return <Styled.Icon src={iconGood} />;
      }
      case MOOD_TYPE.GREAT: {
        return <Styled.Icon src={iconGreat} />;
      }
      case MOOD_TYPE.SAD: {
        return <Styled.Icon src={iconSad} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Input onChange={onChange} type="radio" name={name} value={value} />

      <Styled.IconWrapper color={color} active={active}>
        {renderImage(type)}
      </Styled.IconWrapper>

      <Styled.Text>{label}</Styled.Text>
    </Styled.Wrapper>
  );
};

Mood.defaultProps = {
  color: Color.white,
  label: '',
  name: ''
};

Mood.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  color: PropTypes.string,
  label: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default Mood;
