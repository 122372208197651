import styled from 'styled-components';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  max-height: 100%;
  border-radius: 12px;
  overflow: hidden;

  > * {
    display: block;
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ show }) => (!show ? Color.gray_2 : 'transparent')};
    opacity: 0.38;
    backdrop-filter: blur(1px);
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 52%;
  left: 50%;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  width: 68px;
  height: 68px;
  background-color: transparent;
  border: 0;
  padding: 0;
  opacity: ${({ open }) => (!open ? 1 : 0)};
  transform: translate(-52%, -50%);
  z-index: 100;
`;

export const Icon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;
