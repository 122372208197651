import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundFeedBack1 = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.feedBack1} top={11} right={12} width={115} height={127} index={1} />
    <Image src={IMAGES.feedBack2} top={119} left={145} width={124} height={101} index={3} />
    <Image src={IMAGES.feedBack3} top={-122} left={0} width={375} height={297} index={5} />
    <Image src={IMAGES.feedBack4} top={29} left={32} width={117} height={117} index={4} />
  </Styled.Wrapper>
);

export default BackgroundFeedBack1;
