import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundProgrammeAttitude = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.programmeAttitude_2} top={0} right={85} width={100} height={88} index={1} />
    <Image src={IMAGES.programmeAttitude_1} top={46} left={41} width={157} height={139} index={2} />
    <Image
      src={IMAGES.programmeAttitude_3}
      top={103}
      right={37}
      width={132}
      height={109}
      index={3}
    />
    <Image
      src={IMAGES.programmeAttitude_4}
      top={-55}
      right={0}
      left={0}
      width={375}
      height={238}
      index={4}
    />
  </Styled.Wrapper>
);

export default BackgroundProgrammeAttitude;
