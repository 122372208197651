import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin: 0 0 24px 0;

  > div {
    width: 100%;
  }
`;

export const Error = styled.div`
  display: block;
  width: 100%;
  margin-top: 6px;
  font-size: 10px;
  line-height: 12px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.error};
`;
