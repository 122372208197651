import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Color } from '../../theme/_color';
import * as Styled from './styled';

import imageCP1 from '../../images/img-summary-1.svg';
import imageCP2 from '../../images/img-summary-2.svg';

const CircularProgress = ({ size, strokeWidth, percentage }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <Styled.Wrapper>
      <Styled.Image src={imageCP1} top="-31px" left="0" />

      <svg width={size} height={size} viewBox={viewBox}>
        <circle
          fill={Color.white}
          stroke="transparent"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          fill="none"
          stroke={Color.secondaryGreen}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeDasharray={[dash, circumference - dash]}
          strokeLinecap="round"
          style={{ transition: 'all 0.5s' }}
        />
        <Styled.Text x="50%" y="50%" dy="12px" textAnchor="middle">
          {`${percentage}%`}
        </Styled.Text>
      </svg>

      <Styled.Image src={imageCP2} top="-30px" right="0" />
    </Styled.Wrapper>
  );
};

CircularProgress.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default CircularProgress;
