import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconLock from '../../images/icon-lock.svg';

const ProgramStep = ({ days, blocked, poster, title, programId }) => (
  <Styled.Wrapper>
    <Styled.Poster>
      <Styled.Image src={poster} />
    </Styled.Poster>

    <Styled.Content>
      {days - 1 > 0 && <Styled.Days>dzień {days - 1}</Styled.Days>}
      <Styled.Title padding={blocked}>
        {blocked ? <Styled.Icon src={iconLock} /> : null}
        {blocked ? (
          title
        ) : (
          <Styled.Link to={`/program/${programId}/step/${days}`}>{title}</Styled.Link>
        )}
      </Styled.Title>
    </Styled.Content>
  </Styled.Wrapper>
);

ProgramStep.defaultProps = {
  blocked: false
};

ProgramStep.propTypes = {
  days: PropTypes.number.isRequired,
  blocked: PropTypes.bool,
  poster: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired
};

export default ProgramStep;
