import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  margin-bottom: 22px;
`;

export const Label = styled.span`
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${FontWeight.Bold};
`;

export const StatisticBox = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin-top: 9px;
  border-radius: 13px;
  padding: 30px 14px 19px;
  box-shadow: 0 4px 20px rgba(128, 64, 255, 0.2);
`;

export const Box = styled.div`
  display: flex;
  flex-flow: nowrap row;
  width: 100%;

  > .VictoryContainer {
    width: calc(100% - 40px);
  }
`;

export const Icon = styled.img`
  display: block;
`;

export const LegendY = styled.div`
  display: flex;
  flex-flow: wrap row;
  align-content: space-between;
  width: 40px;
  padding-right: 10px;
  padding-bottom: 40px;
`;

export const LegendX = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  padding-left: 40px;
`;

export const Date = styled.span`
  display: block;
  font-weight: ${FontWeight.Normal};
  font-size: 14px;
  line-height: 16px;
  color: ${Color.gray_4};
`;
