import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import * as Styled from './styled';
import { useQuery } from 'react-query';
import ProgramRepository from '../../repository/Repository/resources/ProgramRepository';
import { ROUTE } from '../../constants';
import { ActiveProgramEmpty } from '../../containers/Dashboard/components';
import { useHistory } from 'react-router-dom';

const ActiveProgram = () => {
  const history = useHistory();
  const [employeeProgram, setEmployeeProgram] = useState(null);

  const { data: employeeProgramData, isLoading: employeeProgramLoading } = useQuery(
    'current-employee-program',
    ProgramRepository.getEmployeeProgram
  );

  useEffect(() => {
    if (employeeProgramLoading) {
      return;
    }
    const { data } = employeeProgramData.data;

    if (!data || !data.length) {
      return;
    }

    setEmployeeProgram(data[0]);
  }, [employeeProgramData, employeeProgramLoading]);

  if (!employeeProgram) {
    return <ActiveProgramEmpty />;
  }

  const { currentProgress = 0, id, img, name } = employeeProgram;

  return (
    <Styled.Wrapper onClick={() => history.push(`${ROUTE.PROGRAM}/${id}`)}>
      <Styled.Poster>
        <Styled.Image src={img} />
      </Styled.Poster>

      <Styled.BottomBar>
        <Styled.Progressbar progress={currentProgress} />

        <Styled.Details>
          <Styled.Text>
            <Styled.Label>
              <FormattedMessage {...messages.title} />
            </Styled.Label>
            <Styled.Title>{name}</Styled.Title>
          </Styled.Text>

          <Styled.Percent>{currentProgress}%</Styled.Percent>
        </Styled.Details>
      </Styled.BottomBar>
    </Styled.Wrapper>
  );
};

export default ActiveProgram;
