import React from 'react';
import PropTypes from 'prop-types';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import * as Styled from './styled';

import icon_1 from '../../images/icon-mood-0.svg';
import icon_2 from '../../images/icon-mood-1.svg';
import icon_3 from '../../images/icon-mood-2.svg';
import icon_4 from '../../images/icon-mood-3.svg';
import icon_5 from '../../images/icon-mood-4.svg';

const StatisticsMonth = ({ data, label }) => {
  if (data.length === 0) {
    return null;
  }

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const firstDay = new Intl.DateTimeFormat('pl-PL', {
    month: '2-digit',
    day: '2-digit'
  }).format(firstDayOfMonth);

  const lastDay = new Intl.DateTimeFormat('pl-PL', {
    month: '2-digit',
    day: '2-digit'
  }).format(lastDayOfMonth);

  return (
    <Styled.Wrapper>
      <Styled.Label>{label}</Styled.Label>
      <Styled.StatisticBox>
        <Styled.Box>
          <Styled.LegendY>
            <Styled.Icon src={icon_5} />
            <Styled.Icon src={icon_4} />
            <Styled.Icon src={icon_3} />
            <Styled.Icon src={icon_2} />
            <Styled.Icon src={icon_1} />
          </Styled.LegendY>

          <VictoryChart padding={{ top: 0, bottom: 0 }}>
            <VictoryAxis
              data={data}
              style={{
                axis: { stroke: '#ffffff' },
                grid: { stroke: '#F6F6F6' },
                ticks: { stroke: '#F6F6F6', size: 0, padding: 6 },
                tickLabels: {
                  display: 'none'
                }
              }}
            />
            <VictoryLine
              domain={{ x: [1, 31], y: [0, 5] }}
              data={data}
              style={{ data: { stroke: '#F5C9BB', strokeWidth: 3 } }}
            />
          </VictoryChart>
        </Styled.Box>

        <Styled.LegendX>
          <Styled.Date>{firstDay}</Styled.Date>
          <Styled.Date>{lastDay}</Styled.Date>
        </Styled.LegendX>
      </Styled.StatisticBox>
    </Styled.Wrapper>
  );
};

StatisticsMonth.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string
};

export default StatisticsMonth;
