import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 264px;
  min-height: 48px;
  border-radius: 32px;
  background-color: ${({ active }) => (active ? Color.primaryBrand : Color.gray_2)};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 23px;
  border: 0;
  font-weight: ${FontWeight.Bold};
  color: ${Color.white};
  box-shadow: ${({ active }) => (active ? `0px 3px 2px rgba(0, 0, 0, 0.16)` : 'none')};
  transition: all 0.3s ease;

  ${({ active }) =>
    !active &&
    `
    pointer-events: none;
    cursor: default;
  `}
`;
