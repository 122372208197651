import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding-left: 22px;
  font-size: 12px;
  line-height: 17px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.gray_6};
`;

export const Img = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 14px;
  height: 15px;
  transform: translateY(-50%);
`;
