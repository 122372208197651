import React from 'react';
import { ImageUniversal } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundForgotPassword = () => (
  <Styled.Wrapper>
    <ImageUniversal
      src={IMAGES.forgotPassword_1}
      top={'99px'}
      left={'50%'}
      translate="-50%, 0"
      index={2}
    />
    <ImageUniversal
      src={IMAGES.forgotPassword_2}
      top={'0'}
      left={'50%'}
      translate="-50%, 0"
      index={1}
    />
    <ImageUniversal src={IMAGES.forgotPassword_3} top={'61px'} left={'0'} width={170} index={1} />
    <ImageUniversal src={IMAGES.forgotPassword_4} top={'50px'} right={'0'} width={138} index={1} />
  </Styled.Wrapper>
);

export default BackgroundForgotPassword;
