import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import reportWebVitals from './reportWebVitals';
import { AuthorizationContextProvider } from './context/AuthorizationContext';
import App from './containers/App';
import 'sanitize.css/sanitize.css';
import GlobalStyle from './global-styles';

import { translationMessages } from './i18n';
import 'nativeBridge/index';

const rootElement = document.getElementById('root');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      retry: false
    }
  }
});

const Router = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider messages={translationMessages.pl} locale="pl" defaultLocale="pl">
        <BrowserRouter>
          <AuthorizationContextProvider>
            <App />
          </AuthorizationContextProvider>
          <GlobalStyle />
        </BrowserRouter>
      </IntlProvider>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  );
};

ReactDOM.render((() => <Router />)(), rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
