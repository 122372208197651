import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Layout';

export default defineMessages({
  navBottomItem1: {
    id: `${scope}.navBottomItem1`,
    defaultMessage: 'home'
  },
  navBottomItem2: {
    id: `${scope}.navBottomItem2`,
    defaultMessage: 'kategorie'
  },
  navBottomItem3: {
    id: `${scope}.navBottomItem3`,
    defaultMessage: 'profil'
  }
});
