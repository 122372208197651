export const formatData = (programs) => {
  if (!programs) return;
  const durationValues = [...new Set(programs.map((program) => program.duration - 1))];
  const convertedObject = durationValues.reduce((acc, curr) => ((acc[curr] = []), acc), {});
  programs.forEach((program) => convertedObject[program.duration - 1].push(program));
  return convertedObject;
};

export const getCategoryName = (categories, id) =>
  categories.data.find((category) => category.id === id).name;

export const getCategoryDescription = (categories, id) =>
  categories.data.find((category) => category.id === id).description;
