import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundTaskSummaryBottom = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.taskSummaryBottom_1} top={0} right={0} width={94} height={128} index={2} />
    <Image src={IMAGES.taskSummaryBottom_2} top={36} left={0} width={375} height={173} index={3} />
    <Image src={IMAGES.taskSummaryBottom_3} top={-5} left={0} width={134} height={142} index={1} />
  </Styled.Wrapper>
);

export default BackgroundTaskSummaryBottom;
