import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  width: calc(100% + 48px);
  margin: 0 -24px;
  padding: 8px 0 14px;
  overflow: hidden;
`;

export const Text = styled.text`
  fill: ${Color.black};
  font-size: 32px;
  font-weight: ${FontWeight.Medium};
`;

export const Image = styled.img`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  display: block;
  z-index: -1;
`;
