import styled, { keyframes } from 'styled-components';
import { Color } from '../../theme/_color';

const opacity = keyframes`
  0% {opacity: 1;}
  100% {opacity: 0;}
`;

export const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 46px;
  height: 46px;
  color: ${Color.white};
  transform: scale(${({ size }) => size});

  > div {
    transform-origin: 24px 24px;
    animation: ${opacity} 1s linear infinite;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 31px;
      left: 23px;
      width: 3px;
      height: 9px;
      border-radius: 20%;
      background: ${({ color }) => color};
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1s;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.875s;
    }
    &:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.75s;
    }
    &:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.625s;
    }
    &:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.375s;
    }
    &:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.25s;
    }
    &:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -0.125s;
    }
  }
`;
