import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';
import Loading from '../Loading';

const Button = ({ active, handleClick, label, loading, type }) => (
  <Styled.Button type={type} active={active} onClick={handleClick}>
    {loading ? <Loading /> : label}
  </Styled.Button>
);

Button.defaultProps = {
  active: false,
  handleClick: () => null,
  loading: false,
  type: 'button'
};

Button.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handleClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  type: PropTypes.string
};

export default Button;
