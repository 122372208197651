import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconTime from '../../images/icon-time.svg';

const CardWithProgress = ({
  category,
  days,
  description,
  handleClick,
  poster,
  title,
  progress
}) => (
  <Styled.Wrapper onClick={handleClick}>
    <Styled.Poster>
      <Styled.Image src={poster} />

      <Styled.Progress>
        <Styled.ProgressBar progress={progress} />
        <Styled.ProgressDesc>ukończono {progress}%</Styled.ProgressDesc>
      </Styled.Progress>
    </Styled.Poster>

    <Styled.WrapperInformation>
      <Styled.Title>{title}</Styled.Title>

      <Styled.Details>
        <Styled.Days>
          <Styled.Icon src={iconTime} />
          {days} dni
        </Styled.Days>

        <Styled.Category>{category}</Styled.Category>
      </Styled.Details>

      <Styled.Desc>{description}</Styled.Desc>
    </Styled.WrapperInformation>
  </Styled.Wrapper>
);

CardWithProgress.propTypes = {
  category: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  poster: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired
};

export default CardWithProgress;
