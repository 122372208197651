import styled from 'styled-components';
import { Color } from '../../theme/_color';

export const Navigation = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-flow: nowrap row;
  width: 100%;
  max-width: 540px;
  height: 73px;
  padding: 0 6px;
  transform: translateX(-50%);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.28);
  background: ${Color.white};
`;
