import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Styled from './styled';

import Arrow from '../../images/icon-arrow-back.svg';

const Back = ({ history, mgrRight, handleClick }) => {
  const onClick = () => {
    handleClick ? handleClick() : history.goBack();
  };

  return <Styled.Image margin={mgrRight} src={Arrow} onClick={onClick} />;
};

Back.propTypes = {
  handleClick: PropTypes.func,
  history: PropTypes.object,
  mgrRight: PropTypes.string
};

export default withRouter(Back);
