import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';

export const Checkbox = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;
`;

export const Check = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: ${({ error }) =>
    error ? `2px solid ${Color.error}` : `2px solid ${Color.primaryBrand}`};
  transform: translateY(-50%);
  border-radius: 5px;
`;

export const Img = styled.img`
  display: block;
  opacity: 0;
  transition: all 0.3s ease;
`;

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding-left: 26px;

  input {
    position: absolute;
    left: -999px;
    top: -999px;
    display: none;
    opacity: 0;
    visibility: hidden;

    &:checked ~ ${Check} {
      & > ${Img} {
        opacity: 1;
      }
    }
  }
`;

export const Text = styled.div`
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  color: ${Color.black};
`;

export const Error = styled.div`
  display: block;
  width: 100%;
  margin-top: 6px;
  font-size: 10px;
  line-height: 12px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.error};
`;
