class NativeTokenFunctionHolder {
  function_ = null;

  setFunction(function_) {
    this.function_ = function_;
  }

  getFunction() {
    return this.function_;
  }
}

export default new NativeTokenFunctionHolder();
