import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

import iconTime from '../../images/icon-time.svg';
import iconWarning from '../../images/icon-warning.svg';

const ImageBanner = ({
  category,
  days,
  description,
  descriptionWarning,
  poster,
  progress,
  title
}) => {
  return (
    <Styled.Wrapper>
      <Styled.Poster>
        <Styled.Image src={poster} />
      </Styled.Poster>

      <Styled.Box>
        {progress > 0 ? (
          <>
            <Styled.ProgressBar progress={progress} />
            <Styled.ProgressBarLabel>{progress}%</Styled.ProgressBarLabel>
          </>
        ) : null}

        <Styled.Title>{title}</Styled.Title>

        <Styled.Details>
          <Styled.Days>
            <Styled.Icon src={iconTime} />
            {days} dni
          </Styled.Days>
          <Styled.Category>{category}</Styled.Category>
        </Styled.Details>

        <Styled.TitleDescription>Opis</Styled.TitleDescription>

        <Styled.Desc>{description}</Styled.Desc>

        {descriptionWarning ? (
          <Styled.Warning>
            <Styled.IconWarning src={iconWarning} />
            {descriptionWarning}
          </Styled.Warning>
        ) : null}
      </Styled.Box>
    </Styled.Wrapper>
  );
};

ImageBanner.defaultProps = {
  description: '',
  descriptionWarning: ''
};

ImageBanner.propTypes = {
  category: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  descriptionWarning: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  poster: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired])
};

export default ImageBanner;
