import React from 'react';
import { Background } from '../../../../components';
import { BACKGROUND_TYPE } from '../../../../constants';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import * as Styled from './styled';

const ActiveProgramEmpty = () => (
  <Styled.ActiveProgramEmpty>
    <Background type={BACKGROUND_TYPE.ACTIVE_PROGRAM} />

    <Styled.Text>
      <FormattedMessage {...messages.text} />
    </Styled.Text>
  </Styled.ActiveProgramEmpty>
);

export default ActiveProgramEmpty;
