import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundTaskSummaryTop = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.taskSummaryTop_1} top={0} left={0} width={375} height={246} index={2} />
    <Image src={IMAGES.taskSummaryTop_2} top={3} right={0} width={170} height={160} index={1} />
  </Styled.Wrapper>
);

export default BackgroundTaskSummaryTop;
