import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import Layout from '../Layout/Layout';
import { Slider } from '../../components/';

import { getCategoryPrograms, GET_CATEGORY_PROGRAMS } from './api';
import { getCategories, GET_CATEGORIES_KEY } from '../Category/api';

import { formatData, getCategoryName, getCategoryDescription } from './helpers';

import * as Styled from './styled';
import { ROUTE } from '../../constants';

const Programs = () => {
  const { id } = useParams();
  const { data: programs } = useQuery([GET_CATEGORY_PROGRAMS, id], () => getCategoryPrograms(id), {
    enabled: !!id
  });
  const { data: categories } = useQuery(GET_CATEGORIES_KEY, getCategories);

  const data = formatData(programs && programs.data);

  const history = useHistory();

  const description = getCategoryDescription(categories, id);

  return programs ? (
    <Layout
      isNav
      title={getCategoryName(categories, id)}
      handleClickBack={() => history.push(ROUTE.DASHBOARD)}
      top="97px"
      isBackButton
      isHamburger>
      <Styled.Desc>{description}</Styled.Desc>

      <Styled.WrapperSlider>
        {Object.keys(data).map((key, i) => (
          <Slider
            key={key}
            title={`${key} dni`}
            items={data[key]}
            categories={categories}
            top={i === 0 ? '0px' : '21px'}
          />
        ))}
      </Styled.WrapperSlider>
    </Layout>
  ) : null;
};

export default Programs;
