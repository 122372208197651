import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 86px;
  max-width: 183px;
  border-radius: 13px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);

  font-size: 32px;
  line-height: 38px;
  color: ${Color.black};
  font-weight: ${FontWeight.Bold};
  letter-spacing: 3px;
`;
