import AccountDetails1 from '../../../images/background/bgAccount-1.svg';
import AccountDetails2 from '../../../images/background/bgAccount-2.svg';
import AccountDetails3 from '../../../images/background/bgAccount-3.svg';
import AccountDetails4 from '../../../images/background/bgAccount-4.svg';
import ActiveProgram1 from '../../../images/background/active-program-1.svg';
import ActiveProgram2 from '../../../images/background/active-program-2.svg';
import ActiveProgram3 from '../../../images/background/active-program-3.svg';
import ActiveProgram4 from '../../../images/background/active-program-4.svg';
import ChangePassword1 from '../../../images/background/change-password-green-wave.svg';
import ChangePassword2 from '../../../images/background/change-password-line.svg';
import ChangePassword3 from '../../../images/background/change-password-yellow-wave.svg';
import ChangePassword4 from '../../../images/background/change-password-gray-wave.svg';
import ChangePasswordConfirm1 from '../../../images/background/changed-green-wave.svg';
import ChangePasswordConfirm2 from '../../../images/background/changed-yellow-wave.svg';
import ChangePasswordConfirm3 from '../../../images/background/changed-gray-wave.svg';
import ChangePasswordConfirm4 from '../../../images/background/changed-flower.svg';
import CodeNotWork1 from '../../../images/background/code-not-work-1.svg';
import CodeNotWork2 from '../../../images/background/code-not-work-2.svg';
import CodeNotWork3 from '../../../images/background/code-not-work-3.svg';
import CodeNotWork4 from '../../../images/background/code-not-work-4.svg';
import DeleteAccount1 from '../../../images/background/bgFaceCenter-1.svg';
import DeleteAccount2 from '../../../images/background/bgFaceCenter-2.svg';
import DeleteAccount3 from '../../../images/background/bgFaceCenter-3.svg';
import DeleteAccount4 from '../../../images/background/bgFaceCenter-4.svg';
import DeleteAccount5 from '../../../images/background/bgFaceCenter-5.svg';
import DeleteAccountConfirm1 from '../../../images/background/bgFaceCenter-6.svg';
import DeleteAccountConfirm2 from '../../../images/background/bgFaceCenter-7.svg';
import DeleteAccountConfirm3 from '../../../images/background/bgFaceCenter-8.svg';
import DeleteAccountConfirm4 from '../../../images/background/bgFaceCenter-9.svg';
import DeleteAccountConfirm5 from '../../../images/background/bgFaceCenter-10.svg';
import FeedBack1 from '../../../images/background/feedback-1.svg';
import FeedBack10 from '../../../images/background/feedback-10.svg';
import FeedBack11 from '../../../images/background/feedback-11.svg';
import FeedBack12 from '../../../images/background/feedback-12.svg';
import FeedBack2 from '../../../images/background/feedback-2.svg';
import FeedBack3 from '../../../images/background/feedback-3.svg';
import FeedBack4 from '../../../images/background/feedback-4.svg';
import FeedBack5 from '../../../images/background/feedback-5.svg';
import FeedBack6 from '../../../images/background/feedback-6.svg';
import FeedBack7 from '../../../images/background/feedback-7.svg';
import FeedBack8 from '../../../images/background/feedback-8.svg';
import FeedBack9 from '../../../images/background/feedback-9.svg';
import ForgotPassword1 from '../../../images/background/forgot-circle.svg';
import ForgotPassword2 from '../../../images/background/forgot-green-wave.svg';
import ForgotPassword3 from '../../../images/background/forgot-yellow-wave.svg';
import ForgotPassword4 from '../../../images/background/forgot-gray-wave.svg';
import ForgotPasswordConfirm1 from '../../../images/background/forgot-password-confirm1.svg';
import ForgotPasswordConfirm2 from '../../../images/background/forgot-password-confirm2.svg';
import ForgotPasswordConfirm3 from '../../../images/background/forgot-password-confirm3.svg';
import ForgotPasswordConfirm4 from '../../../images/background/forgot-password-confirm4.svg';
import ForgotPasswordConfirm5 from '../../../images/background/forgot-password-confirm5.svg';
import Notifications from '../../../images/background/notifications.svg';
import NotificationsEdit from '../../../images/background/notifications-edit.svg';
import PowerAudit1 from '../../../images/background/power-audit-1.svg';
import PowerAudit2 from '../../../images/background/power-audit-2.svg';
import PowerAudit3 from '../../../images/background/power-audit-3.svg';
import PowerAudit4 from '../../../images/background/power-audit-4.svg';
import ProgrammeAttitude1 from '../../../images/background/attitude-gray-wave.svg';
import ProgrammeAttitude2 from '../../../images/background/attitude-green-wave.svg';
import ProgrammeAttitude3 from '../../../images/background/attitude-yellow-wave.svg';
import ProgrammeAttitude4 from '../../../images/background/attitude-line.svg';
import Register1 from '../../../images/background/register_1.svg';
import Register2 from '../../../images/background/register_2.svg';
import Register3 from '../../../images/background/register_3.svg';
import Register4 from '../../../images/background/register_4.svg';
import ReportProblemSuccess1 from '../../../images/background/ReportProblemSuccess1.svg';
import ReportProblemSuccess2 from '../../../images/background/ReportProblemSuccess2.svg';
import ReportProblemSuccess3 from '../../../images/background/ReportProblemSuccess3.svg';
import ReportProblemSuccess4 from '../../../images/background/ReportProblemSuccess4.svg';
import SingIn1 from '../../../images/background/bgDefault-1.svg';
import SingIn2 from '../../../images/background/bgDefault-2.svg';
import SingIn3 from '../../../images/background/bgDefault-3.svg';
import SingIn4 from '../../../images/background/bgDefault-4.svg';
import SingIn5 from '../../../images/background/bgDefault-5.svg';
import SingUp1 from '../../../images/background/sing-up-1.svg';
import SingUp2 from '../../../images/background/sing-up-2.svg';
import SingUp3 from '../../../images/background/sing-up-3.svg';
import SingUp4 from '../../../images/background/sing-up-4.svg';
import SingUp5 from '../../../images/background/sing-up-5.svg';
import SingUp6 from '../../../images/background/sing-up-6.svg';
import StartPage1 from '../../../images/background/start-page-1.svg';
import StartPage10 from '../../../images/background/start-page-10.svg';
import StartPage11 from '../../../images/background/start-page-11.svg';
import StartPage12 from '../../../images/background/start-page-12.svg';
import StartPage13 from '../../../images/background/start-page-13.svg';
import StartPage14 from '../../../images/background/start-page-14.svg';
import StartPage15 from '../../../images/background/start-page-15.svg';
import StartPage16 from '../../../images/background/start-page-16.svg';
import StartPage17 from '../../../images/background/start-page-17.svg';
import StartPage2 from '../../../images/background/start-page-2.svg';
import StartPage3 from '../../../images/background/start-page-3.svg';
import StartPage4 from '../../../images/background/start-page-4.svg';
import StartPage5 from '../../../images/background/start-page-5.svg';
import StartPage6 from '../../../images/background/start-page-6.svg';
import StartPage7 from '../../../images/background/start-page-7.svg';
import StartPage8 from '../../../images/background/start-page-8.svg';
import StartPage9 from '../../../images/background/start-page-9.svg';
import TaskSummaryBottom1 from '../../../images/background/task-summary-bottom-1.svg';
import TaskSummaryBottom2 from '../../../images/background/task-summary-bottom-2.svg';
import TaskSummaryBottom3 from '../../../images/background/task-summary-bottom-3.svg';
import TaskSummaryTop1 from '../../../images/background/task-summary-top-1.svg';
import TaskSummaryTop2 from '../../../images/background/task-summary-top-2.svg';
import UserDetails1 from '../../../images/background/userDetails-1.svg';
import UserDetails2 from '../../../images/background/userDetails-2.svg';
import UserDetails3 from '../../../images/background/userDetails-3.svg';
import UserDetails4 from '../../../images/background/userDetails-4.svg';
import UserDetails5 from '../../../images/background/userDetails-5.svg';

export const IMAGES = {
  accountDetails_1: AccountDetails1,
  accountDetails_2: AccountDetails2,
  accountDetails_3: AccountDetails3,
  accountDetails_4: AccountDetails4,
  activeProgram_1: ActiveProgram1,
  activeProgram_2: ActiveProgram2,
  activeProgram_3: ActiveProgram3,
  activeProgram_4: ActiveProgram4,
  changePasswordConfirm_1: ChangePasswordConfirm1,
  changePasswordConfirm_2: ChangePasswordConfirm2,
  changePasswordConfirm_3: ChangePasswordConfirm3,
  changePasswordConfirm_4: ChangePasswordConfirm4,
  changePassword_1: ChangePassword1,
  changePassword_2: ChangePassword2,
  changePassword_3: ChangePassword3,
  changePassword_4: ChangePassword4,
  codeNotWork1: CodeNotWork1,
  codeNotWork2: CodeNotWork2,
  codeNotWork3: CodeNotWork3,
  codeNotWork4: CodeNotWork4,
  deleteAccountConfirm_1: DeleteAccountConfirm1,
  deleteAccountConfirm_2: DeleteAccountConfirm2,
  deleteAccountConfirm_3: DeleteAccountConfirm3,
  deleteAccountConfirm_4: DeleteAccountConfirm4,
  deleteAccountConfirm_5: DeleteAccountConfirm5,
  deleteAccount_1: DeleteAccount1,
  deleteAccount_2: DeleteAccount2,
  deleteAccount_3: DeleteAccount3,
  deleteAccount_4: DeleteAccount4,
  deleteAccount_5: DeleteAccount5,
  feedBack10: FeedBack10,
  feedBack11: FeedBack11,
  feedBack12: FeedBack12,
  feedBack1: FeedBack1,
  feedBack2: FeedBack2,
  feedBack3: FeedBack3,
  feedBack4: FeedBack4,
  feedBack5: FeedBack5,
  feedBack6: FeedBack6,
  feedBack7: FeedBack7,
  feedBack8: FeedBack8,
  feedBack9: FeedBack9,
  forgotPassword_1: ForgotPassword1,
  forgotPassword_2: ForgotPassword2,
  forgotPassword_3: ForgotPassword3,
  forgotPassword_4: ForgotPassword4,
  forgotPasswordConfirm_1: ForgotPasswordConfirm1,
  forgotPasswordConfirm_2: ForgotPasswordConfirm2,
  forgotPasswordConfirm_3: ForgotPasswordConfirm3,
  forgotPasswordConfirm_4: ForgotPasswordConfirm4,
  forgotPasswordConfirm_5: ForgotPasswordConfirm5,
  notifications: Notifications,
  notificationsEdit: NotificationsEdit,
  powerAudit1: PowerAudit1,
  powerAudit2: PowerAudit2,
  powerAudit3: PowerAudit3,
  powerAudit4: PowerAudit4,
  programmeAttitude_1: ProgrammeAttitude1,
  programmeAttitude_2: ProgrammeAttitude2,
  programmeAttitude_3: ProgrammeAttitude3,
  programmeAttitude_4: ProgrammeAttitude4,
  register_1: Register1,
  register_2: Register2,
  register_3: Register3,
  register_4: Register4,
  reportProblemSuccess1: ReportProblemSuccess1,
  reportProblemSuccess2: ReportProblemSuccess2,
  reportProblemSuccess3: ReportProblemSuccess3,
  reportProblemSuccess4: ReportProblemSuccess4,
  singIn_1: SingIn1,
  singIn_2: SingIn2,
  singIn_3: SingIn3,
  singIn_4: SingIn4,
  singIn_5: SingIn5,
  singUp_1: SingUp1,
  singUp_2: SingUp2,
  singUp_3: SingUp3,
  singUp_4: SingUp4,
  singUp_5: SingUp5,
  singUp_6: SingUp6,
  startPage_10: StartPage10,
  startPage_11: StartPage11,
  startPage_12: StartPage12,
  startPage_13: StartPage13,
  startPage_14: StartPage14,
  startPage_15: StartPage15,
  startPage_16: StartPage16,
  startPage_17: StartPage17,
  startPage_1: StartPage1,
  startPage_2: StartPage2,
  startPage_3: StartPage3,
  startPage_4: StartPage4,
  startPage_5: StartPage5,
  startPage_6: StartPage6,
  startPage_7: StartPage7,
  startPage_8: StartPage8,
  startPage_9: StartPage9,
  taskSummaryBottom_1: TaskSummaryBottom1,
  taskSummaryBottom_2: TaskSummaryBottom2,
  taskSummaryBottom_3: TaskSummaryBottom3,
  taskSummaryTop_1: TaskSummaryTop1,
  taskSummaryTop_2: TaskSummaryTop2,
  userDetails_1: UserDetails1,
  userDetails_2: UserDetails2,
  userDetails_3: UserDetails3,
  userDetails_4: UserDetails4,
  userDetails_5: UserDetails5
};
