import React from 'react';
import { ImageUniversal } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundChangePasswordConfirm = () => (
  <Styled.Wrapper>
    <ImageUniversal
      src={IMAGES.changePasswordConfirm_3}
      top="75px"
      left="0"
      width={67}
      height={277}
      index={1}
    />
    <ImageUniversal
      src={IMAGES.changePasswordConfirm_2}
      top="19px"
      left="59px"
      width={199}
      height={187}
      index={1}
    />
    <ImageUniversal
      src={IMAGES.changePasswordConfirm_1}
      top="53px"
      right="0"
      width={122}
      height={270}
      index={1}
    />
    <ImageUniversal
      src={IMAGES.changePasswordConfirm_4}
      top="0"
      right="0"
      width={256}
      height={183}
      index={1}
    />
  </Styled.Wrapper>
);

export default BackgroundChangePasswordConfirm;
