import styled from 'styled-components';

export const Image = styled.img`
  display: block;
  position: absolute;
  top: ${({ top }) => `${top}px` || 'unset'};
  left: ${({ left }) => `${left}px` || 'unset'};
  right: ${({ right }) => `${right}px` || 'unset'};
  width: ${({ width }) => `${width}px` || 'unset'};
  height: ${({ height }) => `${height}px` || 'unset'};
  z-index: ${({ index }) => index || 1};
`;

export const ImageUniversal = styled.img`
  display: block;
  position: absolute;
  top: ${({ top }) => top || 'unset'};
  left: ${({ left }) => left || 'unset'};
  right: ${({ right }) => right || 'unset'};
  width: ${({ width }) => width || 'unset'};
  height: ${({ height }) => `${height}px` || 'unset'};
  transform: translate(${({ translate }) => (translate ? translate : '0 0')});
  z-index: ${({ index }) => index || 1};
`;
