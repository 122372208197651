import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';
import { Color } from '../../theme/_color';

const Loading = ({ color, size }) => (
  <Styled.Wrapper color={color} size={size}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </Styled.Wrapper>
);

Loading.defaultProps = {
  color: Color.white,
  size: '1'
};

Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

export default Loading;
