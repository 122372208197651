import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  margin-bottom: 22px;
`;

export const Label = styled.span`
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: ${FontWeight.Bold};
`;

export const StatisticBox = styled.div`
  display: block;
  width: 100%;
  margin-top: 9px;
  border-radius: 13px;
  padding: 16px 14px 19px;
  box-shadow: 0 4px 20px rgba(128, 64, 255, 0.2);
`;

export const Icon = styled.img`
  display: block;
`;
