import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding: 50px 16px 0 24px;
  ${({ isEdit }) => (isEdit ? `padding: 50px 16px 0 24px;` : null)}
  ${({ isClose, isHamburger }) => (isClose || isHamburger ? `padding: 50px 64px 0 24px;` : null)}
  z-index: 999;
`;

export const Title = styled.span`
  display: block;
  font-size: 24px;
  line-height: 28px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.black};
`;

export const Edit = styled.button`
  position: absolute;
  top: 47px;
  right: 24px;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  width: 25px;
  height: 25px;
  background: transparent;
  border: 0;
`;

export const Close = styled.button`
  position: absolute;
  top: 48px;
  right: 28px;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  width: 30px;
  height: 30px;
  background: transparent;
  border: 0;
`;

export const IconEdit = styled.img`
  display: block;
  width: 25px;
  height: 25px;
`;

export const IconClose = styled.img`
  display: block;
  width: 30px;
  height: 30px;
`;
