import styled from 'styled-components';
import { H4 } from '../../theme/_typography';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap column;
  align-items: flex-start;
  width: 100%;
  min-height: 192px;
  box-shadow: 0 4px 20px rgba(128, 64, 255, 0.2);
  border-radius: 24px;
  overflow: hidden;
`;

export const Poster = styled.div`
  display: flex;
  width: 100%;
  height: 129px;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 129px;
  object-fit: cover;
  object-position: bottom;
`;

export const BottomBar = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px 30px 14px 28px;
  background-color: ${Color.white};
  z-index: 10;
`;

export const Progressbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 8px;
  background-color: ${Color.gray_1};
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: ${({ progress }) => progress}%;
    height: 8px;
    background-color: ${Color.secondaryGreen};
    z-index: 20;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  width: 100%;
`;

export const Text = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: calc(100% - 42px);
`;

export const Label = styled.span`
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.gray_5};
`;

export const Title = styled(H4)`
  width: 100%;
  margin-top: 1px;
`;

export const Percent = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 2px;
  font-size: 16px;
  line-height: 20px;
  font-weight: ${FontWeight.Normal};
  text-align: right;
  color: ${Color.gray_5};
`;
