import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  padding: 16px 32px 16px 98px;
  width: 100%;
  min-height: 70px;
  border-radius: 24px;
  background: ${({ color }) => color};
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: ${FontWeight.Medium};
  line-height: 22px;
  color: ${Color.black};
`;

export const Icon = styled.img`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 32px;
  display: block;
  max-height: 34px;
  max-width: 34px;
`;
