import styled from 'styled-components';
import { FontWeight } from '../../theme/_fonts';

export const Title = styled.div`
  width: 100%;
  margin: 24px auto 48px;
  text-align: center;
  font-weight: ${FontWeight.Medium};
`;

export const BackgroundWrapper = styled.div`
  display: block;
  width: calc(100% + 48);
  margin: 0 -24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  width: calc(100% + 48);
  margin: auto -24px 80px;
`;

export const Icon = styled.img`
  display: block;
  width: 74px;
  height: 74px;
  margin: 29px auto 0;
`;
