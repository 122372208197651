import styled from 'styled-components';
import { Color } from '../../theme/_color';

export const Switch = styled.div`
  position: relative;
  display: block;
  width: 54px;
  height: 27px;
  border-radius: 14px;
  background-color: ${Color.gray_2};
  transition: all 0.3s ease;
`;

export const Circle = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: ${Color.white};
  transition: left 0.3s ease;
`;

export const Wrapper = styled.label`
  position: relative;
  display: block;

  input {
    position: absolute;
    top: -999px;
    left: -999px;
    display: none;
    visibility: hidden;

    &:checked ~ ${Switch} {
      background-color: ${Color.secondaryBlueDark};

      > ${Circle} {
        left: 30px;
      }
    }
  }
`;
