import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';
import { H2 } from '../../theme/_typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: calc(100% + 48px);
  overflow: hidden;
  margin-left: -24px;
  margin-right: -24px;
`;

export const Box = styled.div`
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Poster = styled.div`
  display: flex;
  flex-flow: wrap row;
  height: 209px;
  overflow: hidden;
  position: relative;
`;

export const ProgressBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  height: 8px;
  background-color: ${Color.gray_1};
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: ${({ progress }) => progress}%;
    height: 100%;
    background-color: ${Color.secondaryGreen};
    z-index: 20;
  }
`;

export const ProgressBarLabel = styled.span`
  position: absolute;
  top: 14px;
  right: 24px;
  text-align: right;
  color: ${Color.gray_4};
  font-size: 16px;
  font-weight: 400;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
`;

export const Title = styled(H2)`
  width: 100%;
  margin: 29px 0 8px 0;
  font-weight: ${FontWeight.Bold};
  color: ${Color.neutralBlack};
  text-align: center;
`;

export const Details = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  width: 100%;
  margin-top: 4px;
  padding-right: 6px;
  text-align: center;
`;

export const Icon = styled.img`
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
`;

export const Days = styled.span`
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  margin-right: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray_4};
  font-weight: ${FontWeight.Normal};
`;

export const Category = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 13px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.gray_4};
  font-weight: ${FontWeight.Normal};

  &:first-letter {
    text-transform: uppercase;
  }

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${Color.gray_2};
  }
`;

export const TitleDescription = styled.h3`
  width: 100%;
  margin: 18px 0 0;
  font-weight: ${FontWeight.Medium};
  color: ${Color.neutralBlack};
`;

export const Desc = styled.div`
  display: block;
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  font-weight: ${FontWeight.Normal};
  color: ${Color.neutralBlack};
`;

export const Warning = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.black};
  font-weight: ${FontWeight.Normal};
`;

export const IconWarning = styled.img`
  display: block;
  width: 13.33px;
  height: 16.67px;
  margin-right: 12px;
`;
