import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundDeleteAccountConfirm = () => (
  <Styled.Wrapper>
    <Image
      src={IMAGES.deleteAccountConfirm_1}
      top={125}
      left={135}
      width={106}
      height={116}
      index={5}
    />
    <Image
      src={IMAGES.deleteAccountConfirm_2}
      top={0}
      right={0}
      width={187}
      height={217}
      index={1}
    />
    <Image
      src={IMAGES.deleteAccountConfirm_3}
      top={142}
      left={128}
      width={137}
      height={150}
      index={2}
    />
    <Image
      src={IMAGES.deleteAccountConfirm_4}
      top={52}
      left={0}
      width={375}
      height={308}
      index={4}
    />
    <Image
      src={IMAGES.deleteAccountConfirm_5}
      top={10}
      left={0}
      width={152}
      height={186}
      index={3}
    />
  </Styled.Wrapper>
);

export default BackgroundDeleteAccountConfirm;
