import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundAccountDetails = () => (
  <Styled.Wrapper>
    <Image
      src={IMAGES.reportProblemSuccess1}
      top={0}
      right={0}
      width={147}
      height={188}
      index={1}
    />
    <Image
      src={IMAGES.reportProblemSuccess2}
      top={144}
      right={80}
      width={145}
      height={159}
      index={2}
    />
    <Image
      src={IMAGES.reportProblemSuccess3}
      top={53}
      left={0}
      width={375}
      height={307}
      index={4}
    />
    <Image src={IMAGES.reportProblemSuccess4} top={0} left={0} width={204} height={175} index={3} />
  </Styled.Wrapper>
);

export default BackgroundAccountDetails;
