import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundFeedBack3 = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.feedBack9} top={57} left={0} width={77} height={131} index={1} />
    <Image src={IMAGES.feedBack10} top={0} right={0} width={112} height={229} index={2} />
    <Image src={IMAGES.feedBack11} top={7} left={0} width={375} height={288} index={4} />
    <Image src={IMAGES.feedBack12} top={20} left={83} width={167} height={181} index={3} />
  </Styled.Wrapper>
);

export default BackgroundFeedBack3;
