import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { Link } from 'react-router-dom';
import { FontWeight } from '../../theme/_fonts';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const Item = styled(Link)`
  display: flex;
  flex-flow: wrap row;
  width: 100%;
  padding: 30px 24px 28px;
  border-top: 2px solid ${Color.gray_1};
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.black};
  text-decoration: none;

  &:last-child {
    border-bottom: 2px solid ${Color.gray_1};
  }
`;
