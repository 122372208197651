import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundFeedBack2 = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.feedBack5} top={4} right={114} width={155} height={127} index={1} />
    <Image src={IMAGES.feedBack6} top={123} left={40} width={94} height={104} index={2} />
    <Image src={IMAGES.feedBack7} top={55} left={0} width={375} height={338} index={4} />
    <Image src={IMAGES.feedBack8} top={110} right={24} width={130} height={130} index={3} />
  </Styled.Wrapper>
);

export default BackgroundFeedBack2;
