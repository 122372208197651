import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../../../Layout/Layout';
import { Tabs } from '../../../../../../components';
import * as Styled from './styled';
import { JUSTIFY } from '../../../../../../constants';
import NumberList from '../NumberList';

const Instructions = ({ handleClickBack, title }) => {
  const renderTabs = [
    {
      id: '1',
      tabTitle: 'Android',
      tabContent: (
        <NumberList
          items={[
            'Wpisz https://wellq.pl w przeglądarce Google Chrome na swoim telefonie.',
            'Następnie w prawym górnym rogu przeglądarki kliknij w 3 kropki z rozwijanym menu.',
            'Kliknij „Dodaj do ekranu głównego”.',
            'Pojawi się komunikat: dodaj do ekranu głównego aplikację WellQ. Kliknij „Dodaj”.',
            'Gotowe! Możesz zacząć korzystać z aplikacji na swoim telefonie.'
          ]}
        />
      )
    },
    {
      id: '2',
      tabTitle: 'iOS',
      tabContent: (
        <NumberList
          items={[
            'Wpisz https://wellq.pl w przeglądarce Safari na swoim telefonie.',
            'Następnie na dolnym pasku odszukaj ikonę „Udostępnij”. Kliknij ją.',
            'Pojawi się rozwijane menu. Kliknij „Do ekranu początk.”',
            'Pojawi się komunikat o możliwości dodania aplikacji WellQ do ekranu startowego. Kliknij „Dodaj”.',
            'Gotowe! Możesz zacząć korzystać z aplikacji na swoim telefonie.'
          ]}
        />
      )
    }
  ];

  return (
    <Styled.Wrapper>
      <Layout title={title} top="124px" handleClickBack={handleClickBack} isBackButton>
        <Tabs data={renderTabs} justify={JUSTIFY.SPACE_AROUND} />
      </Layout>
    </Styled.Wrapper>
  );
};

Instructions.propTypes = {
  handleClickBack: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default Instructions;
