import React from 'react';
import { Image, ImageUniversal } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundStartPage3 = () => (
  <Styled.Wrapper>
    <ImageUniversal
      src={IMAGES.startPage_3}
      top="120px"
      left="50%"
      width={173}
      height={27}
      translate="-50%, 0"
      index={3}
    />
    <Image src={IMAGES.startPage_14} top={177} left={49} width={248} height={268} index={10} />
    <Image src={IMAGES.startPage_15} top={0} right={0} width={375} height={89} index={2} />
    <Image src={IMAGES.startPage_16} top={119} right={0} width={220} height={319} index={3} />
    <Image src={IMAGES.startPage_17} top={151} left={0} width={107} height={300} index={4} />
  </Styled.Wrapper>
);

export default BackgroundStartPage3;
