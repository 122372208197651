import React from 'react';
import PropTypes from 'prop-types';
import NavigationItem from '../NavigationItem';
import * as Styled from './styled';

const Navigation = ({ items }) => {
  const length = items.length;
  const renderItems = items.map((item, key) => (
    <NavigationItem
      key={key}
      icon={item.icon}
      iconActive={item.iconActive}
      name={item.name}
      path={item.path}
      length={length}
    />
  ));

  return <Styled.Navigation>{renderItems}</Styled.Navigation>;
};

Navigation.defaultProps = {
  items: []
};

Navigation.propTypes = {
  items: PropTypes.array
};

export default Navigation;
