import Repository from '../Repository';

const resource = 'program';

export default {
  getEmployeeProgram: () => {
    return Repository.get(`${resource}/current-employee-program`);
  },
  myList: () => {
    return Repository.get(`${resource}/my/list`);
  },
  proposedPrograms: (limit = 4) => {
    return Repository.get(`${resource}/propose/employee?limit=${limit}`);
  }
};
