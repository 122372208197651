import { getStartPageStatus, setStartPageStatus } from '../utils/startPage.utils';

export const onRequestRejected = ({ response }) => {
  const { status } = response;

  // Clear data and reload app for status 401, session expired
  if (status === 401) {
    let startPage = getStartPageStatus();
    localStorage.clear();
    setStartPageStatus(startPage);
    window.location.reload();
    return;
  }

  return Promise.reject(response);
};
