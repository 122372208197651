import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '../index';
import { Color } from '../../theme/_color';
import * as Styled from './styled';

const StaticTimeBox = ({ time, loading }) => (
  <Styled.Wrapper>{loading ? <Loading color={Color.gray_3} /> : time}</Styled.Wrapper>
);

StaticTimeBox.propTypes = {
  time: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default StaticTimeBox;
