import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import * as Styled from './styled';

import iconPlay from '../../images/icon-play-button.svg';

const Video = ({ onStartClickCallback, url, showPlayButton }) => {
  return <ReactPlayer url={url} width="100%" height="198px" controls={true} />;
};

Video.propTypes = {
  onStartClickCallback: PropTypes.func,
  url: PropTypes.string,
  showPlayButton: PropTypes.bool
};

export default Video;
