import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundChangePassword = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.changePassword_1} top={0} left={0} width={146} height={224} index={1} />
    <Image src={IMAGES.changePassword_2} top={33} left={0} width={375} height={158} index={4} />
    <Image src={IMAGES.changePassword_3} top={15} left={155} width={110} height={102} index={2} />
    <Image src={IMAGES.changePassword_4} top={-18} right={0} width={134} height={359} index={3} />
  </Styled.Wrapper>
);

export default BackgroundChangePassword;
