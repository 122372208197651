import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundCodeNotWork = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.codeNotWork1} top={160} left={83} width={234} height={126} index={1} />
    <Image src={IMAGES.codeNotWork2} top={102} left={0} width={83} height={171} index={2} />
    <Image src={IMAGES.codeNotWork3} top={-39} left={0} width={375} height={325} index={10} />
    <Image src={IMAGES.codeNotWork4} top={0} right={0} width={108} height={204} index={4} />
  </Styled.Wrapper>
);

export default BackgroundCodeNotWork;
