import React from 'react';
import { Confirm } from '../../components';
import { BACKGROUND_TYPE, ROUTE } from '../../constants';
import { useHistory } from 'react-router-dom';

const ForgotPasswordSuccess = () => {
  const history = useHistory();
  return (
    <Confirm
      backgroundType={BACKGROUND_TYPE.FORGOT_PASSWORD_CONFIRM}
      text="Jeśli podane konto istnieje, to wysłaliśmy na nie wiadomość z nowym hasłem."
      buttonLabel="POWRÓT DO LOGOWANIA"
      handleClick={() => history.push(ROUTE.SIGN_IN)}
    />
  );
};

export default ForgotPasswordSuccess;
