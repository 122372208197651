import React from 'react';
import { Confirm } from '../../components';
import { BACKGROUND_TYPE } from '../../constants';

const LogOutConfirm = () => (
  <Confirm
    handleClick={() => window.location.reload()}
    buttonLabel="POWRÓT DO LOGOWANIA"
    text="Wylogowano pomyślnie!"
    backgroundType={BACKGROUND_TYPE.FORGOT_PASSWORD_CONFIRM}
  />
);

export default LogOutConfirm;
