const IS_REGISTERED = 'isRegistered';
const IS_LOGIN = 'isLogin';
const REGISTRATION_TOKEN = 'registrationToken';
const AUTHORIZATION_TOKEN = 'token';

export const setRegistrationToken = (token) => {
  localStorage.setItem(REGISTRATION_TOKEN, token);
};

export const getRegistrationToken = () => localStorage.getItem(REGISTRATION_TOKEN);

export const removeRegistrationToken = () => localStorage.removeItem(REGISTRATION_TOKEN);

export const setAuthorizationToken = (token) => {
  localStorage.setItem(AUTHORIZATION_TOKEN, token);
};

export const getAuthorizationToken = () => localStorage.getItem(AUTHORIZATION_TOKEN);

export const removeAuthorizationToken = () => localStorage.removeItem(AUTHORIZATION_TOKEN);

export const setIsRegistered = (value) => {
  localStorage.setItem(IS_REGISTERED, value);
};

export const getIsRegistered = () => localStorage.getItem(IS_REGISTERED);

export const removeIsRegistered = () => localStorage.removeItem(IS_REGISTERED);

export const setIsLogin = (value) => {
  localStorage.setItem(IS_LOGIN, value);
};

export const getIsLogin = () => localStorage.getItem(IS_LOGIN);

export const removeIsLogin = () => localStorage.removeItem(IS_LOGIN);
