import styled from 'styled-components';
import { Color } from '../../theme/_color';
import { FontWeight } from '../../theme/_fonts';

export const Button = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: ${({ active }) => (active ? '0 52px 0 22px' : '0 22px')};
  background-color: transparent;
  text-align: left;
  border: 2px solid ${({ active }) => (active ? Color.primaryBrand : Color.gray_2)};
  border-radius: 42px;
  box-sizing: border-box;

  font-size: 16px;
  line-height: 18px;
  font-weight: ${({ active }) => (active ? FontWeight.Bold : FontWeight.Normal)};
  color: ${Color.neutralBlack};

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Icon = styled.img`
  position: absolute;
  top: 19px;
  right: 23px;
  display: block;
`;
