import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const InputTime = ({ placeholder, value, onChange }) => (
  <Styled.Wrapper>
    <Styled.Input type="time" placeholder={placeholder} value={value} onChange={onChange} />
  </Styled.Wrapper>
);

InputTime.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default InputTime;
