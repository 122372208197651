import React from 'react';
import { Image, ImageUniversal } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundStartPage1 = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.startPage_1} top={314} left={107} width={116} height={92} index={10} />
    <Image src={IMAGES.startPage_2} top={168} right={0} width={176} height={207} index={12} />
    <ImageUniversal
      src={IMAGES.startPage_3}
      top="120px"
      left="50%"
      width={173}
      height={27}
      translate="-50%, 0"
      index={3}
    />
    <Image src={IMAGES.startPage_4} top={122} left={0} width={147} height={377} index={4} />
    <Image src={IMAGES.startPage_5} top={0} left={0} width={375} height={211} index={5} />
    <Image src={IMAGES.startPage_6} top={0} right={0} width={242} height={122} index={6} />
    <Image src={IMAGES.startPage_7} top={102} left={0} width={100} height={174} index={7} />
    <Image src={IMAGES.startPage_8} top={167} right={0} width={186} height={277} index={8} />
  </Styled.Wrapper>
);

export default BackgroundStartPage1;
