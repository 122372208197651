import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundRegister = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.register_1} top={-36} right={0} width={375} height={220} index={4} />
    <Image src={IMAGES.register_2} top={92} left={0} width={375} height={246} index={3} />
    <Image src={IMAGES.register_3} top={23} right={0} width={231} height={245} index={2} />
    <Image src={IMAGES.register_4} top={0} left={0} width={115} height={253} index={1} />
  </Styled.Wrapper>
);

export default BackgroundRegister;
