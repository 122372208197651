import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const ButtonRadio = ({ active, label, name, onChange, value }) => (
  <Styled.Wrapper active={active}>
    <Styled.Input type="radio" value={value} name={name} onChange={onChange} checked={active} />
    {label}
  </Styled.Wrapper>
);

ButtonRadio.defaultProps = {
  active: false,
  type: 'button'
};

ButtonRadio.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.string
};

export default ButtonRadio;
