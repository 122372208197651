import styled from 'styled-components';
import { Color } from '../../theme/_color';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: wrap column;
  width: auto;
  overflow: hidden;
  z-index: 20;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
  }
`;

export const Image = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-content: center;
  width: 50px;
  height: 50px;
  border-radius: 7px;
  background: ${({ color }) => (color ? color : Color.white)};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
`;

export const Label = styled.span`
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 5px;
`;

export const Icon = styled.img`
  display: block;
  width: 29px;
  height: 29px;
`;
