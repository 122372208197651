import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Card from '../Card';
import * as Styled from './styled';

import { ROUTE } from '../../constants';

import { getCategoryName } from '../../containers/Programs/helpers';

const Slider = ({ items, title, top, categories }) => {
  const history = useHistory();

  const renderItems = items.map((item, key) => (
    <Card
      key={key}
      category={getCategoryName(categories, item.mainCategory)}
      days={item.duration - 1}
      poster={item.img}
      title={item.name}
      description={item.description}
      handleClick={() =>
        history.push({
          pathname: `${ROUTE.PROGRAM}/${item.id}`
        })
      }
    />
  ));

  return (
    <Styled.Panel top={top}>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Slider>{renderItems}</Styled.Slider>
    </Styled.Panel>
  );
};

Slider.defaultProps = {
  items: [],
  categories: {},
  top: '0px'
};

Slider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
      mainCategory: PropTypes.string,
      name: PropTypes.string,
      img: PropTypes.string,
      duration: PropTypes.number,
      notificationTime: PropTypes.number,
      description: PropTypes.string,
      steps: PropTypes.array
    })
  ),
  top: PropTypes.string,
  title: PropTypes.string,
  categories: PropTypes.object
};

export default Slider;
