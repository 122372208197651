import styled from 'styled-components';
import { Color } from '../../../../theme/_color';
import { FontWeight } from '../../../../theme/_fonts';

export const ActiveProgramEmpty = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  width: 100%;
  border: 2px dashed ${Color.primaryBrand};
  border-radius: 24px;
  padding: 15px 24px 23px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

export const Text = styled.span`
  display: block;
  width: 100%;
  margin-top: 9px;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${FontWeight.Normal};
  text-align: center;
  color: ${Color.gray_4};
`;
