import React from 'react';
import { Image } from '../styled';
import { IMAGES } from '../helpers';
import * as Styled from './styled';

const BackgroundSingIn = () => (
  <Styled.Wrapper>
    <Image src={IMAGES.singIn_1} top={0} left={0} width={286} height={122} index={4} />
    <Image src={IMAGES.singIn_2} top={12} right={0} width={223} height={363} index={5} />
    <Image src={IMAGES.singIn_3} top={121} left={0} width={185} height={171} index={3} />
    <Image src={IMAGES.singIn_4} top={159} left={0} width={183} height={258} index={2} />
    <Image src={IMAGES.singIn_5} top={69} right={0} width={142} height={312} index={1} />
  </Styled.Wrapper>
);

export default BackgroundSingIn;
