import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';
import { JUSTIFY } from '../../constants';

const Tabs = ({ data, justify }) => {
  const [visibleTab, setVisibleTab] = useState(data[0].id);

  const navTab = data.map((item) => (
    <Styled.TabsPath
      key={item.id}
      active={visibleTab === item.id}
      onClick={() => setVisibleTab(item.id)}>
      {item.tabTitle}
    </Styled.TabsPath>
  ));

  const content = data.map((item) => (
    <Styled.Content key={item.id} active={visibleTab === item.id}>
      {item.tabContent}
    </Styled.Content>
  ));

  return (
    <Styled.Tabs>
      <Styled.NavTabs justify={justify}>{navTab}</Styled.NavTabs>

      <Styled.TabContent>{content}</Styled.TabContent>
    </Styled.Tabs>
  );
};

Tabs.defaultProps = {
  justify: JUSTIFY.SPACE_BETWEEN
};

Tabs.propTypes = {
  data: PropTypes.array.isRequired,
  justify: PropTypes.string
};

export default Tabs;
