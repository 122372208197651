export const Color = {
  black: '#000000',
  neutralBlack: '#121212',
  white: '#ffffff',
  gray_1: '#DFDFDF',
  gray_2: '#C4C4C4',
  gray_3: '#8F8F8F',
  gray_4: '#5C5C5C',
  gray_5: '#404040',
  gray_6: '#656565',
  gray_7: '#707070',

  primaryBrand: '#8040FF',
  primaryDark: '#6516FF',
  primaryLight: '#B28AFF',

  secondarySalmon: '#F5C9BB',
  secondarySalmonDark: '#FFA88C',
  secondaryBlue: '#9FAEE3',
  secondaryBlueDark: '#86A1FF',
  secondaryGrey: '#878d8e',
  secondaryGreyDark: '#404848',
  secondaryGreen: '#6BA7AF',
  secondaryGreenDark: '#6DA9B1',
  secondaryViolet: '#DCABFF',
  secondaryVioletDark: '#CD89FF',

  success: '#00C851',
  error: '#FF4444'
};
