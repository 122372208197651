import { useState } from 'react';

export function useNativeToken() {
  const [isNativeTokenChanged, setNativeTokenChanged] = useState(false);
  const [nativeToken, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    try {
      const item = window.localStorage.getItem('nativeToken');
      return item ? JSON.parse(item) : '';
    } catch (error) {
      console.log(error);
      return '';
    }
  });
  const [isNativeTokenWasSent, setIsNativeTokenWasSentState] = useState(() => {
    if (typeof window === 'undefined') {
      return false;
    }
    try {
      const item = window.localStorage.getItem('nativeTokenWasSent');
      return item ? JSON.parse(item) : '';
    } catch (error) {
      console.log(error);
      return '';
    }
  });
  const setIsNativeTokenWasSent = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(nativeToken) : value;
      setIsNativeTokenWasSentState(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('nativeTokenWasSent', JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setNativeToken = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(isNativeTokenWasSent) : value;
      setStoredValue(valueToStore);
      if (nativeToken !== valueToStore) {
        setNativeTokenChanged(true);
        setIsNativeTokenWasSent(false);
      }
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('nativeToken', JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [
    nativeToken,
    setNativeToken,
    isNativeTokenChanged,
    isNativeTokenWasSent,
    setIsNativeTokenWasSent
  ];
}
