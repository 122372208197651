import Repository from '../Repository';

const resource = 'employee/';

export default {
  getEmployee: () => {
    return Repository.get(resource);
  },
  getMood: () => {
    return Repository.get(`${resource}mood`);
  },
  getMoodAverageWeek: () => {
    return Repository.get(`${resource}mood/week`);
  },
  getMoodAverageMonth: () => {
    return Repository.get(`${resource}mood/month`);
  },
  saveMood: ({ mood }) => {
    return Repository.post(`${resource}mood`, { mood });
  },
  saveNotificationTime: ({ time }) => {
    return Repository.post(`${resource}notification_time`, { time: time });
  },
  toggleNotification: ({ isEnabled }) => {
    return Repository.post(`${resource}notification`, { isEnabled });
  },
  saveToken: ({ token }) => {
    return Repository.post(`${resource}notification/device`, { token });
  }
};
