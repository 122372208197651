import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from '../../theme/_global';
import { FormattedMessage } from 'react-intl';
import { NavBar, Navigation } from '../../components';
import { ROUTE } from '../../constants';
import messages from './messages';

import iconNavigation1 from '../../images/navigation/navigation-home-bar.svg';
import iconNavigation2 from '../../images/navigation/navigation-category-bar.svg';
import iconNavigation3 from '../../images/navigation/navigation-profile-bar.svg';
import iconNavigationActive1 from '../../images/navigation/navigation-home-bar-active.svg';
import iconNavigationActive2 from '../../images/navigation/navigation-category-bar-active.svg';
import iconNavigationActive3 from '../../images/navigation/navigation-profile-bar-active.svg';

const Layout = ({
  children,
  handleClickClose,
  handleClickEdit,
  handleClickBack,
  isBackButton,
  isHamburger,
  isNav,
  title,
  top
}) => {
  const menuBottomItems = [
    {
      icon: iconNavigation1,
      iconActive: iconNavigationActive1,
      name: <FormattedMessage {...messages.navBottomItem1} />,
      path: ROUTE.DASHBOARD
    },
    {
      icon: iconNavigation2,
      iconActive: iconNavigationActive2,
      name: <FormattedMessage {...messages.navBottomItem2} />,
      path: ROUTE.CATEGORIES
    },
    {
      icon: iconNavigation3,
      iconActive: iconNavigationActive3,
      name: <FormattedMessage {...messages.navBottomItem3} />,
      path: ROUTE.PROFILE
    }
  ];

  return (
    <>
      <Wrapper top={top} bottom={isNav}>
        {(title || handleClickClose || handleClickEdit || isBackButton || isHamburger) && (
          <NavBar
            title={title}
            handleClickClose={handleClickClose}
            handleClickEdit={handleClickEdit}
            handleClickBack={handleClickBack}
            isBackButton={isBackButton}
            isHamburger={isHamburger}
          />
        )}

        {children}
      </Wrapper>

      {isNav && <Navigation items={menuBottomItems} />}
    </>
  );
};

Layout.defaultProps = {
  handleClickClose: null,
  handleClickEdit: null,
  isBackButton: false,
  isHamburger: false,
  isNav: false,
  title: '',
  top: ''
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  handleClickClose: PropTypes.func,
  handleClickEdit: PropTypes.func,
  handleClickBack: PropTypes.func,
  isBackButton: PropTypes.bool,
  isHamburger: PropTypes.bool,
  isNav: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  top: PropTypes.string
};

export default Layout;
